import { Box, Link, Text, useColorModeValue } from "@chakra-ui/react";
import Truncate from "components/Truncate/Truncate";
import React from "react";

export default function Information(props) {
  const { title, value, isLink, ...rest } = props;

  const textColorPrimary = useColorModeValue("#DBEAFE", "rgb(255, 255, 255, 0.3)");
  const textColorSecondary = useColorModeValue("#1767D0", "#fff");
  const bg = useColorModeValue("white", "navy.700");
  return (
      <Box bg={bg} {...rest} py={2}>
        {/* <Text fontWeight="500" color={textColorPrimary} fontSize="sm">
          {title}
        </Text> */}
        {isLink ? (
          <Link px={2} py={0.5} borderRadius='50px' bg={textColorPrimary} href={value} isExternal>
            <Text color={textColorSecondary} fontWeight="400" fontSize="sm">
              {value}
            </Text>
          </Link>
        ) : (
          <Text color={textColorSecondary} px={2} py={0.5} borderRadius='50px' bg={textColorPrimary} fontWeight="400" fontSize="sm">
            <Truncate text={value} length={20} />
          </Text>
        )}
      </Box>
  );
}
