import React, { useMemo, useState } from "react";
import {
  Button,
  Flex,
  SimpleGrid,
  Box,
  Stack,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetAvatarsQuery, useAssignAssetMutation } from "store/api/heygen";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useGetAllUsersQuery } from "store/api/user";
import AutoComplete from "components/autocomplete";
import { useDebounceValue } from "usehooks-ts";

const HeygenVideos = () => {
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue("", 500);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const {
    items: videosData,
    ref,
    isLoading,
    isFetching,
    loadMore,
  } = useInfiniteScroll(useGetAvatarsQuery);

  const { data: users, isFetching: usersFetching } = useGetAllUsersQuery(
    {
      search: debouncedSearch,
      isAdmin: true,
    },
    {
      skip: selectedUser,
    }
  );

  const [assignAsset, { isLoading: assignLoading }] = useAssignAssetMutation();

  const handleCloseModal = () => {
    setIsAssignModalOpen(false);
    setInputValue("");
    setSelectedUser(null);
  };

  const handleAssign = async () => {
    try {
      await assignAsset({
        assetId: selectedVideo,
        body: { user_id: selectedUser },
      });
      toast({
        title: "Video has been assigned successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch {
      toast({
        title: "Failed to assign video",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    handleCloseModal();
  };

  const handleSelectVideo = (id) => {
    setIsAssignModalOpen(true);
    setSelectedVideo(id);
  };

  const menuOptions = [
    { id: 1, title: "Assign", method: (id) => handleSelectVideo(id) },
  ];

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ title: user?.name, value: user?.id })),
    [users]
  );

  return (
    <Flex direction="column">
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
        spacing={{ base: "40px", xl: "40px" }}
      >
        {videosData?.map((video) => (
          <VideoCard
            video={video}
            key={video?.avatar_id}
            showName
            menuOptions={menuOptions}
            showMenu={true}
          />
        ))}
      </SimpleGrid>

      {(isLoading || isFetching) && <Loader />}
      {loadMore && <Box ref={ref} style={{ height: "20px" }} />}
      <Modal
        title="Assign Audio to User"
        isModalOpen={isAssignModalOpen}
        setIsModalOpen={handleCloseModal}
      >
        <Stack py={4} gap={0}>
          <FormLabel>Select User</FormLabel>
          <AutoComplete
            data={userOptions ?? []}
            placeholder="Search user"
            setSelectedValue={setSelectedUser}
            selectedValue={selectedUser}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setDebouncedSearch={setDebouncedSearch}
            isLoading={usersFetching}
          />
          <Stack direction="row" justifyContent="center">
            <Button
              mt={6}
              variant="brand"
              onClick={handleAssign}
              isLoading={assignLoading}
              isDisabled={assignLoading || !selectedUser}
            >
              Assign
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Flex>
  );
};

export default HeygenVideos;
