import {
  Box,
  Card,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useListOfSubmittedAvatarsQuery } from "store/api/avatar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SubmittedAvatar = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const bgCard = useColorModeValue("#fff", "#434343");
  const userDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = userDetails.user?.user_metadata?.is_admin;
  const { data, isLoading, isFetching } = useListOfSubmittedAvatarsQuery({
    isAdmin,
  });

  const columns = [
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    ...(isAdmin
      ? [
          {
            Header: "Name",
            accessor: "user.name",
          },
          {
            Header: "Company",
            accessor: "user.company",
          },
        ]
      : []),
    {
      Header: "Audios",
      accessor: "audio_assets[0].url",
      link: true,
      linkText: "Audio Link",
    },
    {
      Header: "Videos",
      accessor: "video_assets[0].url",
      link: true,
      linkText: "Video Link",
    },
    {
      Header: "Script",
      accessor: "csv_asset.url",
      link: true,
      linkText: "Script Link",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        return (
          <Box>
            <Text
              cursor="pointer"
              color="blue.400"
              onClick={() => navigate(row.original.id)}
            >
              View More
            </Text>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        {isLoading || isFetching ? (
          <Loader />
        ) : data?.data?.length ? (
          <CustomTable
            columnsData={columns}
            tableData={data?.data ?? []}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={data?.totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Card
            bg={bgCard}
            p={4}
            borderRadius={15}
            h={400}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              fontSize={{ base: 20, md: 25 }}
              color="gray"
              fontWeight="bold"
            >
              No Avatar Available
            </Text>
          </Card>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default SubmittedAvatar;
