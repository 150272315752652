import { Link, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React from "react";

const RowItem = ({
  children,
  value,
  shouldCopy,
  isLink,

  linkDisplayText,
  preWrap,
}) => {
  const textColor = useColorModeValue("#000", "#fff");
  const toolTipBg = useColorModeValue("white", "#434343");

  return (
    <Text
      fontSize="sm"
      fontWeight="700"
      textOverflow="ellipsis"
      overflow="hidden"
      width="100%"
      cursor={shouldCopy && "pointer"}
      position="relative"
      textTransform="capitalize"
      whiteSpace={preWrap ? "pre-wrap" : "nowrap"}
    >
      <Tooltip
        label={isLink && value ? "Click to view" : value}
        placement="right"
        bg={toolTipBg}
        color={textColor}
      >
        {isLink ? (
          !isEmpty(value) ? (
            <Link href={value} isExternal color="blue.400">
              {linkDisplayText ?? "Link"}
            </Link>
          ) : (
            "N/A"
          )
        ) : (
          children
        )}
      </Tooltip>
    </Text>
  );
};

export default RowItem;
