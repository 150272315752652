export const fileTypes = {
  audio: {
    "audio/mpeg": [".mp3"], // MP3
    "audio/wav": [".wav"], // WAV
    "audio/ogg": [".ogg"], // OGG
  },
  video: {
    "video/mp4": [".mp4"], // MP4
    "video/webm": [".webm"], // WebM
    "video/ogg": [".ogg"], // OGG
    "video/quicktime": [".mov"], // MOV
    "video/x-msvideo": [".avi"], // AVI
    "video/x-matroska": [".mkv"], // MKV
  },
  csv: {
    "text/csv": [".csv"], // CSV
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ], // XLSX
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "text/plain": [".txt"], // Text files
  },
};

export const textFileExtensions = ["doc", "docx", "txt", "Doc", "Docx"];
