import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Information from "components/Information/Information";
import FileMenu from "components/menu/FileMenu";
import { FaPause, FaPlay, FaVolumeUp } from "react-icons/fa";
import { MdGraphicEq } from "react-icons/md";
import { BiSolidVolumeMute } from "react-icons/bi";
import { TfiControlSkipBackward, TfiControlSkipForward } from "react-icons/tfi";
import { CiCalendar } from "react-icons/ci";

const AudioCard = ({ key, voice, menuOptions, onPlay }) => {
  const cardBackground = useColorModeValue("lightModeBg.100", "darkModeBg.100");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const shadowColor = useColorModeValue("#c7c7c7", "none");
  const sliderBg = useColorModeValue("gray.200", "rgb(255,255,255,0.2)");
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      onPlay(audio);
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMute = () => {
    const audio = audioRef.current;
    audio.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleSkipBackward = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.max(0, audio.currentTime - 5);
    setCurrentTime(audio.currentTime);
  };

  const handleSkipForward = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.min(audio.duration, audio.currentTime + 5);
    setCurrentTime(audio.currentTime);
  };

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      setCurrentTime(audio.currentTime);
    };

    const updateDuration = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", updateDuration);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", updateDuration);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Card
      bg={cardBackground}
      py={2}
      minHeight="200px"
      key={key}
      shadow={`1px 1px 8px 1px ${shadowColor}`}
    >
      <Flex h="100%" direction="column" justifyContent="center">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="lg" fontWeight={700} textTransform="capitalize">
            {voice?.display_name ?? voice?.name}
          </Text>
          <FileMenu menuOptions={menuOptions} id={voice?.id} />
        </Flex>
        <Flex alignItems="center" flexWrap="wrap" gap={3}>
          <Information
            bg={""}
            title="Language"
            value={voice?.language ?? "English"}
          />
          <Information bg={""} title="Gender" value={voice?.gender ?? "Male"} />
        </Flex>
        <Flex justifyContent='space-between' gap={2} alignItems='center' flexWrap='wrap'>
          <Box> 
          <Text fontSize="xs" fontWeight={700} textTransform="capitalize">Created at </Text>
        <HStack spacing="2">
          <CiCalendar size={20} />
          <Text fontSize="xs">
            {new Date(voice?.created_at).toLocaleDateString()}
          </Text>
          <Text fontSize="xs">
            {new Date(voice?.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
        </HStack>
          </Box>
          <Box>
          <Text fontSize="xs" fontWeight={700} textTransform="capitalize">Updated at </Text>
        <HStack spacing="2">
          <CiCalendar size={20} />
          <Text fontSize="xs">
            {new Date(voice?.updated_at).toLocaleDateString()}
          </Text>
          <Text fontSize="xs">
            {new Date(voice?.updated_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
        </HStack>
          </Box>
        </Flex>
        {/* Audio Player */}
        <audio ref={audioRef} style={{ display: "none" }}>
          <source src={voice?.url ?? voice?.preview?.movio} />
        </audio>
        <Box mt={2} display="flex" alignItems="center" gap={3}>
          <IconButton
            icon={isPlaying ? <FaPause /> : <FaPlay />}
            onClick={handlePlayPause}
            aria-label="Play/Pause"
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
          <Slider
            aria-label="audio-duration"
            flex="1"
            value={(currentTime / duration) * 100 || 0} // Slider position in percentage
            onChange={(val) => {
              const newTime = (val / 100) * duration;
              audioRef.current.currentTime = newTime; // Seek audio
              setCurrentTime(newTime); // Update state
            }}
            mr={2}
          >
            <SliderTrack bg={sliderBg}>
              <SliderFilledTrack bg="#1767D0" />
            </SliderTrack>
            <SliderThumb boxSize={6} border="1px solid #DBEAFE" shadow="md">
              <Box color="#1767D0" as={MdGraphicEq} />
            </SliderThumb>
          </Slider>
          <Text>{formatTime(duration - currentTime)}</Text>{" "}
          {/* Remaining time */}
        </Box>
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box display="flex" gap={3}>
            <IconButton
              icon={<TfiControlSkipBackward size={20} />}
              aria-label="Skip Backward"
              onClick={handleSkipBackward}
              p={0}
              bg="transparent"
              _hover={{ bg: "transparent" }}
            />
            <IconButton
              icon={<TfiControlSkipForward size={20} />}
              aria-label="Skip Forward"
              onClick={handleSkipForward}
              p={0}
              bg="transparent"
              _hover={{ bg: "transparent" }}
            />
          </Box>
          <IconButton
            icon={isMuted ? <BiSolidVolumeMute /> : <FaVolumeUp />}
            aria-label="Volume"
            onClick={handleMute}
            p={0}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default AudioCard;
