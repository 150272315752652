import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/user";
import avatarReducer from "./slices/avatar";
import { avatarApi } from "store/api/avatar";
import { userApi } from "store/api/user";
import { authApi } from "store/api/auth";
import { companyApi } from "store/api/company";
import { heygenApi } from "store/api/heygen";
import { tagApi } from "store/api/tag";

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["userDetails", "isAdmin", "userRole"],
};

const avatarPersistConfig = {
  key: "avatar",
  storage,
  whitelist: ["audiosCount", "videosCount"],
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const persistedAvatarReducer = persistReducer(
  avatarPersistConfig,
  avatarReducer
);

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [avatarApi.reducerPath]: avatarApi.reducer,
  [heygenApi.reducerPath]: heygenApi.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  user: persistedUserReducer,
  avatar: persistedAvatarReducer,
});

export const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([
        authApi.middleware,
        avatarApi.middleware,
        heygenApi.middleware,
        tagApi.middleware,
        userApi.middleware,
        companyApi.middleware,
      ]),
  });
  return store;
};

export const store = setupStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);
