import { Box, Heading, useColorModeValue, SimpleGrid, Card } from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React from "react";
import { useGetAllFilesQuery } from "store/api/avatar";
import VideoCard from "components/card/VideoCard";

const RecentVideo = () => {
  // const [playingVideo, setPlayingVideo] = useState(null);
  const { data, isLoading } = useGetAllFilesQuery({ type: "video" });
  const tableBg = useColorModeValue("#fff", "#434343");
  const avatars = data?.data || [];
  const recentAvatars = avatars.slice(0, 4);
  // const headers = avatars.length > 0 ? Object.keys(avatars[0]) : [];
  const avatarsWithRefs = recentAvatars?.map((avatar) => ({
    ...avatar,
    ref: React.createRef(),
  }));
  // const handlePlay = (index) => {
  //   if (playingVideo !== null && playingVideo !== index) {
  //     document.getElementById(`video-${playingVideo}`).pause();
  //     document.getElementById(`video-${playingVideo}`).currentTime = 0;
  //   }
  //   setPlayingVideo(index);
  // };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!avatarsWithRefs || avatarsWithRefs.length === 0) {
    return (
      <Card p={5} bg={tableBg} borderRadius={15} textAlign="start" >
      <Heading as="h2" size="lg" mb={4}>Recent Videos</Heading>
      <Box display='flex' justifyContent='center' alignItems='center' h={250} color='gray' fontWeight='bold' fontSize={20}>No Video Available</Box>
  </Card>
    );
}

  return (
    <Box p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recent Videos
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
        {avatarsWithRefs?.map((avatar, index) => (
          <VideoCard video={avatar} key={index} menuOptions="" showName/>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RecentVideo;
