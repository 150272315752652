import {
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  useToast,
  Input,
  SimpleGrid,
  Stack,
  useColorModeValue,
  Textarea,
  Badge,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import CustomTable from "components/tables/CustomTable";
import AudioCard from "components/card/AudioCard";
import { useMemo } from "react";
import Loader from "components/loader/Loader";
import { useSelector } from "react-redux";
import VideoPlayer from "views/admin/dashboard/components/VideoPlayer";
import { MdCloudUpload, MdCloudDownload } from "react-icons/md";
import Modal from "components/modal";
import Upload from "components/upload/Upload";
import { uploadFileWithProgress } from "superbase/apis";
import {
  useUpdateAvatarRequestMutation,
  useGetAvatarDetailsQuery,
} from "store/api/avatar";
import { fileUpload } from "store/constants";
import { urls } from "routes/urls";
import { downloadFile } from "utils/downloadFile";
import { textFileExtensions } from "constants/fileTypes";
import { isEmpty } from "lodash";
import { createFileObject } from "utils/createTextFileObject";
import useCheckCharacterLength from "hooks/useCSVCharacterCount";
import useFileProcessor from "hooks/useFileProcessor";
import useFileFetcher from "hooks/useFileFetcher";
import CustomScriptUpload from "components/upload/CustomScriptUpload";
import { handleDownloadTextFile } from "utils/downloadFile";
import InputTag from "components/inputTags";
import { useForm } from "react-hook-form";

const SubmittedAvatarDetails = () => {
  const maxCharacterLength = 2000;

  const toast = useToast();
  const navigate = useNavigate();
  const { avatarId } = useParams();

  const userDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = userDetails.user?.user_metadata?.is_admin;

  const checkCharacterLength = useCheckCharacterLength(maxCharacterLength);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const defaultModalsValues = {
    isAvatarModalOpen: false,
    isVideoModalOpen: false,
    isAudioModalOpen: false,
    isCSVModalOpen: false,
  };
  const [modals, setModals] = useState(defaultModalsValues);

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [, setFileContentPreview] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [acceptDoc, setAcceptDoc] = useState();
  const [hasCustomScript, setHasCustomScript] = useState(false);
  const [customScriptContent, setCustomScriptContent] = useState("");
  const [suggestionTag, setSuggestionTag] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    video: {},
  });

  const toolTipBg = useColorModeValue("white", "#434343");
  const textColor = useColorModeValue("#000", "#fff");

  const {
    data: avatarData,
    isLoading: getAvatarLoading,
    isFetching: getAvatarFetching,
  } = useGetAvatarDetailsQuery({
    id: avatarId,
    isAdmin,
  });

  const avatarDetails = useMemo(() => avatarData?.data, [avatarData]);

  const defaultValues = useMemo(
    () => ({
      note: {
        description: avatarDetails?.note?.description || "",
        title: avatarDetails?.note?.title || "",
      },
      tags: avatarDetails?.tags || [],
      type: avatarDetails?.type || "",
      status: avatarDetails?.status || "",
    }),
    [avatarDetails]
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    if (avatarDetails) {
      reset(defaultValues);
    }
  }, [avatarDetails, reset, defaultValues]);

  useFileProcessor(
    selectedFiles,
    checkCharacterLength,
    setCsvData,
    setFileContentPreview,
    setSelectedFiles,
    setAcceptDoc
  );

  const { handleFileFromUrl } = useFileFetcher(
    setFileContent,
    setCsvData,
    textFileExtensions
  );

  const [updateAvatarRequest, { isLoading: avatarLoading }] =
    useUpdateAvatarRequestMutation();

  const carbg = useColorModeValue("#fff", "#434343");
  const inputColor = useColorModeValue("#000", "#fff");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handlePlay = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      document.getElementById(`video-${playingVideo}`).pause();
      document.getElementById(`video-${playingVideo}`).currentTime = 0;
    }
    setPlayingVideo(index);
  };
  const currentlyPlayingRef = useRef(null);
  const handleAudioPlay = (audioRef) => {
    // If another audio is currently playing and it's different from the one being played
    if (
      currentlyPlayingRef.current &&
      currentlyPlayingRef.current !== audioRef
    ) {
      currentlyPlayingRef.current.pause(); // Pause the currently playing audio
      currentlyPlayingRef.current.currentTime = 0; // Reset it to the beginning
    }
    currentlyPlayingRef.current = audioRef; // Set the new audio as the currently playing one
  };

  useEffect(() => {
    const csvUrl = avatarDetails?.csv_asset?.url;
    if (csvUrl) handleFileFromUrl(csvUrl);
  }, [avatarDetails, handleFileFromUrl]);

  const handlePatchRequest = (body, shouldNavigate) => {
    updateAvatarRequest({ body, id: avatarId, isAdmin }).then((result) => {
      if (result.error) {
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Avatar request has been submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        if (shouldNavigate) navigate(urls.submittedAvatars);
      }
    });
  };

  const handleUpload = async (id, uploadType) => {
    setLoading(true);
    let textFileObject = null;
    if (hasCustomScript && customScriptContent) {
      textFileObject = createFileObject(customScriptContent);
    }
    const isVideo = uploadType === "video" || uploadType === "avatar";
    const uploadVideo =
      isVideo &&
      (await uploadFileWithProgress(
        selectedFiles.video?.file,
        userDetails?.user?.id,
        fileUpload[`video`],
        "dummy-co"
      ));

    const uploadAudio =
      uploadType === "audio" &&
      id &&
      (await uploadFileWithProgress(
        selectedFiles?.audio?.file,
        userDetails?.user?.id,
        fileUpload[`audio`],
        "dummy-co"
      ));

    const uploadCSV =
      (selectedFiles.csv?.file || !!textFileObject) &&
      (await uploadFileWithProgress(
        textFileObject ?? selectedFiles.csv?.file,
        userDetails?.user?.id,
        fileUpload[`csv`],
        "dummy-co"
      ));

    let addedAssets = [];

    if (uploadVideo) {
      addedAssets.push({
        name: `video-${new Date().getTime()}`,
        storage_path: uploadVideo.path,
        supabase_bucket: "video-files",
        type: "video",
      });
    }

    if (uploadAudio) {
      addedAssets.push({
        name: `audio-${new Date().getTime()}`,
        storage_path: uploadAudio.path,
        supabase_bucket: "audio-files",
        type: "audio",
      });
    }

    if (uploadCSV)
      addedAssets.push({
        name: `csv-${new Date().getTime()}`,
        storage_path: uploadCSV.path,
        supabase_bucket: "csv-files",
        type: acceptDoc || hasCustomScript ? "doc" : "csv",
      });

    const body = {
      added_assets: [...addedAssets],
      type: avatarDetails?.type,
      ...(uploadType === "avatar" && { status: "completed" }),
      ...(id && { removed_assets: [id] }),
    };
    handlePatchRequest(body, false);
    setLoading(false);
    setModals(defaultModalsValues);
    setHasCustomScript(false);
    setCustomScriptContent(null);
  };

  const handleUpdateDetails = (body) => {
    if (!isEmpty(body)) handlePatchRequest(body, true);
  };

  const onSubmit = (data) => {
    handleUpdateDetails(data);
  };

  const columns = [
    {
      Header: "Script",
      accessor: "message",
      copy: true,
      preWrap: true,
    },
  ];

  const audioMenuOptions = useMemo(
    () => [
      {
        id: 1,
        title: "Download",
        method: () => {
          const audioURL = avatarDetails?.audio_assets?.[0]?.url;
          const fileName = `${avatarId}-audio`;
          downloadFile(audioURL, fileName);
        },
      },
    ],
    [avatarDetails, avatarId]
  );

  const isComleted = useMemo(
    () => avatarDetails?.status === "completed",
    [avatarDetails]
  );

  const isLoading = useMemo(
    () => loading || avatarLoading || getAvatarLoading || getAvatarFetching,
    [loading, avatarLoading, getAvatarLoading, getAvatarFetching]
  );

  const isTextFile = useMemo(
    () => avatarDetails?.csv_asset?.storage_path?.split(".")?.pop() === "txt",
    [avatarDetails]
  );

  const hasChanges = JSON.stringify(defaultValues) !== JSON.stringify(watch());

  return (
    <Flex direction="column">
      <Flex direction="row" justifyContent="flex-end">
        {isComleted ? (
          <Badge variant="solid" colorScheme="green" borderRadius={5}>
            Completed
          </Badge>
        ) : (
          <Flex direction="row" gap={3} alignItems="center">
            {isAdmin && (
              <Button
                width="max-content"
                ml="auto"
                variant="brand"
                onClick={() =>
                  setModals((prev) => ({
                    ...prev,
                    isAvatarModalOpen: true,
                  }))
                }
                borderRadius={5}
              >
                <Flex direction="row" gap={3} alignItems="center">
                  <MdCloudUpload size={30} />
                  Upload Avatar
                </Flex>
              </Button>
            )}
          </Flex>
        )}
      </Flex>

      <Card
        bg={carbg}
        borderRadius={15}
        mt={{ base: 2, md: 2 }}
        mb={{ base: 4, md: 5 }}
        p={{ base: 4, md: 5 }}
        shadow="md"
      >
        <Box flex="2" p={3}>
          <form>
            <Stack spacing={3}>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Type</FormLabel>
                  <Select
                    isDisabled={!isEditing || isAdmin}
                    {...register("type")}
                    placeholder="Select option"
                  >
                    <option value="static">Static</option>
                    <option value="moving">Moving</option>
                    <option value="streaming">Streaming</option>
                  </Select>
                </Box>
                {isAdmin ? (
                  <Box w="100%">
                    <FormLabel>Status</FormLabel>
                    <Select
                      isDisabled={!isEditing}
                      {...register("status")}
                      placeholder="Select option"
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="rejected">Rejected</option>
                      <option value="completed">Completed</option>
                    </Select>
                  </Box>
                ) : (
                  <Box w="100%">
                    <FormLabel>Avatar ID</FormLabel>
                    <Input
                      placeholder="Avatar ID"
                      color={inputColor}
                      value={avatarDetails?.video_assets?.[0]?.id}
                      disabled
                    />
                  </Box>
                )}
              </Flex>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Title</FormLabel>
                  <Input
                    isDisabled={!isEditing}
                    color="black.900"
                    placeholder="Enter the title for avatar..."
                    {...register("note.title")}
                    type="text"
                  />
                </Box>
                <Box w="100%">
                  <FormLabel>Tags</FormLabel>
                  <InputTag
                    isDisabled={!isEditing}
                    tagsList={avatarDetails?.tags}
                    onTagsChange={(tags) => setValue("tags", [...tags])}
                    maxWidth="100%"
                    suggestionTag={suggestionTag}
                    setSuggestionTag={setSuggestionTag}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box w="100%">
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    color={inputColor}
                    {...register("note.description")}
                    isDisabled={!isEditing}
                    placeholder="Avatar description / note"
                    resize="none"
                    minH="100px"
                  />
                </Box>
              </Flex>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Created date</FormLabel>
                  <Input
                    placeholder="Created Date"
                    color={inputColor}
                    disabled
                    value={new Date(
                      avatarDetails?.created_at
                    ).toLocaleDateString()}
                  />
                </Box>
                <Box w="100%">
                  <FormLabel>Created Time</FormLabel>
                  <Input
                    placeholder="Created Time"
                    color={inputColor}
                    disabled
                    value={new Date(
                      avatarDetails?.created_at
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  />
                </Box>
              </Flex>
            </Stack>
            <Flex justifyContent="end" gap={3} mt={5}>
              {isEditing ? (
                <Flex justifyContent="end" gap={3}>
                  <Tooltip
                    label="Please make any change to update"
                    placement="top"
                    bg={toolTipBg}
                    color={textColor}
                    isDisabled={isSubmitting}
                  >
                    <Button
                      variant="brand"
                      px={4}
                      onClick={handleSubmit(onSubmit)}
                      borderRadius={5}
                      isDisabled={isSubmitting || !hasChanges}
                    >
                      Update
                    </Button>
                  </Tooltip>
                  <Button variant="ghost" px={4} onClick={handleCancelClick}>
                    Cancel
                  </Button>
                </Flex>
              ) : (
                !isComleted && (
                  <Button
                    width="max-content"
                    ml="auto"
                    variant="brand"
                    onClick={handleEditClick}
                    borderRadius={5}
                  >
                    <Flex direction="row" gap={3} alignItems="center">
                      Edit
                    </Flex>
                  </Button>
                )
              )}
            </Flex>
          </form>
        </Box>
      </Card>
      {getAvatarLoading ? (
        <Loader />
      ) : (
        <>
          {csvData?.length || !!fileContent ? (
            <SimpleGrid
              mt={10}
              columns={1}
              spacing={{ base: "10px", xl: "10px" }}
            >
              <>
                <Flex direction="row" gap={3}>
                  <Button
                    width="max-content"
                    ml="auto"
                    variant="brand"
                    onClick={() =>
                      isTextFile
                        ? handleDownloadTextFile(
                            avatarDetails?.csv_asset?.url,
                            `${avatarId}-csv`
                          )
                        : downloadFile(
                            avatarDetails?.csv_asset?.url,
                            `${avatarId}-csv`
                          )
                    }
                    borderRadius={5}
                  >
                    <Flex direction="row" gap={3} alignItems="center">
                      <MdCloudDownload size={30} />
                      Donwload Script
                    </Flex>
                  </Button>
                  {!isComleted && !isAdmin && (
                    <Button
                      width="max-content"
                      variant="brand"
                      onClick={() =>
                        setModals((prev) => ({
                          ...prev,
                          isCSVModalOpen: true,
                        }))
                      }
                      borderRadius={5}
                    >
                      Edit
                    </Button>
                  )}
                </Flex>

                {!!csvData?.length && (
                  <CustomTable
                    columnsData={columns}
                    tableData={csvData ?? []}
                    totalItems={csvData?.length}
                  />
                )}
                {!!fileContent && (
                  <Textarea
                    cursor="default"
                    placeholder="File content"
                    value={fileContent}
                    rows={20}
                    bg="white"
                  />
                )}
              </>
            </SimpleGrid>
          ) : (
            ""
          )}
          <SimpleGrid
            mt={{ sm: 10, base: 3 }}
            columns={{ sm: 1, md: 1, lg: 2, xl: 2 }}
            spacing={{ base: "20px", xl: "10px" }}
          >
            <Flex direction="column" gap={3}>
              {!isComleted && !isAdmin && (
                <Button
                  width="max-content"
                  variant="brand"
                  onClick={() =>
                    setModals((prev) => ({ ...prev, isVideoModalOpen: true }))
                  }
                  borderRadius={5}
                >
                  Edit
                </Button>
              )}
              <VideoPlayer
                videoSrc={avatarDetails?.video_assets?.[0]?.url}
                onPlay={() => handlePlay(avatarDetails?.video_assets?.[0]?.id)}
                videoId={`video-${avatarDetails?.video_assets?.[0]?.id}`}
                userName={
                  avatarDetails?.video_assets?.[0]?.name || "Unknown Avatar"
                }
              />
            </Flex>
            {avatarDetails?.audio_assets?.[0]?.url && (
              <Flex direction="column" gap={3}>
                {!isComleted && !isAdmin && (
                  <Button
                    width="max-content"
                    variant="brand"
                    onClick={() =>
                      setModals((prev) => ({ ...prev, isAudioModalOpen: true }))
                    }
                    borderRadius={5}
                  >
                    Edit
                  </Button>
                )}
                <AudioCard
                  onPlay={handleAudioPlay}
                  voice={avatarDetails?.audio_assets?.[0]}
                  menuOptions={audioMenuOptions}
                />
              </Flex>
            )}
          </SimpleGrid>
        </>
      )}
      <Modal
        title="Upload Script"
        isModalOpen={modals.isCSVModalOpen}
        setIsModalOpen={() =>
          setModals((prev) => ({ ...prev, isCSVModalOpen: false }))
        }
      >
        <CustomScriptUpload
          hasCustomScript={hasCustomScript}
          setHasCustomScript={setHasCustomScript}
          customScriptContent={customScriptContent}
          setCustomScriptContent={setCustomScriptContent}
          isLoading={isLoading}
          setSelectedFiles={setSelectedFiles}
          setIsModalOpen={() =>
            setModals((prev) => ({ ...prev, isCSVModalOpen: false }))
          }
          handleFileUpload={() =>
            handleUpload(avatarDetails?.csv_assets?.id, "csv")
          }
          hideUpload={true}
          isDisabled={!selectedFiles.csv?.file && !customScriptContent}
        />
      </Modal>
      <Modal
        title="Upload Video"
        isModalOpen={modals.isVideoModalOpen}
        setIsModalOpen={() =>
          setModals((prev) => ({ ...prev, isVideoModalOpen: false }))
        }
      >
        <Upload
          setIsModalOpen={() =>
            setModals((prev) => ({ ...prev, isVideoModalOpen: true }))
          }
          setSelectedFiles={setSelectedFiles}
          uploadLoading={loading || avatarLoading}
          handleFileUpload={() =>
            handleUpload(avatarDetails?.video_assets?.[0]?.id, "video")
          }
          description="MP4, MOV, and MKV files are allowed"
          accept="video"
          w="100%"
          h="300px"
        />
      </Modal>
      <Modal
        title="Upload Audio"
        isModalOpen={modals.isAudioModalOpen}
        setIsModalOpen={() =>
          setModals((prev) => ({ ...prev, isAudioModalOpen: false }))
        }
      >
        <Upload
          setIsModalOpen={() =>
            setModals((prev) => ({ ...prev, isAudioModalOpen: true }))
          }
          setSelectedFiles={setSelectedFiles}
          uploadLoading={loading || avatarLoading}
          handleFileUpload={() =>
            handleUpload(avatarDetails?.audio_assets?.[0]?.id, "audio")
          }
          description="MP3, WAV, AAC, FLAC, and OGG files are allowed"
          accept="audio"
          w="100%"
          h="300px"
        />
      </Modal>
      <Modal
        title="Upload Avatar Video"
        isModalOpen={modals.isAvatarModalOpen}
        setIsModalOpen={() =>
          setModals((prev) => ({ ...prev, isAvatarModalOpen: false }))
        }
      >
        <Upload
          setIsModalOpen={() =>
            setModals((prev) => ({ ...prev, isAvatarModalOpen: true }))
          }
          setSelectedFiles={setSelectedFiles}
          uploadLoading={loading || avatarLoading}
          handleFileUpload={() => handleUpload(null, "avatar")}
          description="MP4, MOV, and MKV files are allowed"
          accept="video"
          w="100%"
          h="300px"
        />
      </Modal>
    </Flex>
  );
};

export default SubmittedAvatarDetails;
