export const downloadFile = (fileUrl, fileName) => {
  if (fileUrl) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `${fileName}.txt`; // Optional: Specifies the file name for download
    link.target = "_blank"; // Opens in new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  } else alert("File url is not valid");
};

export const handleDownloadTextFile = (url, fileName) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error fetching the file:", error);
    });
};
