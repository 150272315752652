import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { setCookieWithExpiry } from "utils/auth";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { supabase } from "superbase/auth";
import { urls } from "routes/urls";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { saveUserDetails } from "store/slices/user";
import { useDispatch } from "react-redux";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);

  const textColor = useColorModeValue("navy.700", "white");
  const SignInButton = useColorModeValue("#000", "#3C3C3C");

  const textColorSecondary = "gray.400";
  const brandStars = "red.400";
  const borderColor = useColorModeValue("black", "inherit");
  const placeholderColor = "gray";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const handleClick = () => setShow(!show);

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        toast({
          title: error.message || "Login failed",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        if (data?.session) {
          dispatch(saveUserDetails(data));
          const session = data?.session;
          const accessToken = session?.access_token;
          localStorage.setItem("accessToken", accessToken);
          setCookieWithExpiry(session?.access_token, session?.expires_at);
          toast({
            title: "Login successfull",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setTimeout(() => {
            navigate(urls.dashboard);
          }, 1000);
        } else {
          toast({
            title: "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
      }
      setIsLoading(false);
    },
    [email, password, toast, navigate, dispatch]
  );

  const handleKeyDown = useCallback(
    (ev) => {
      if (ev.key === "Enter") {
        handleLogin(ev);
      }
    },
    [handleLogin]
  );

  useEffect(() => {
    // Add event listener for the 'Enter' key
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box textAlign="center" w="full">
          <Heading size="md" mb={5}>
            Avatars AskmeAI
          </Heading>
        </Box>
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email{" "}
              <Text ml={1} color={brandStars}>
                *
              </Text>
            </FormLabel>
            <Input
              isRequired={true}
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@simmmple.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              borderColor={borderColor}
              _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password
              <Text ml={1} color={brandStars}>
                *
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                borderColor={borderColor}
                _placeholder={{ color: placeholderColor }}
                color={placeholderColor}
                error
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize="sm"
              color="white"
              variant="brand"
              bg={SignInButton}
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleLogin}
              isLoading={isLoading}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to={urls.signUp}>
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
        <FixedPlugin />
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
