import { Box, Card, Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetDownloadsFilesQuery } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const Downloads = () => {
  const bgCard = useColorModeValue('#fff', '#434343')
  const {
    items: data,
    ref,
    isLoading,
    isFetching,
    hasMore,
  } = useInfiniteScroll(useGetDownloadsFilesQuery);

  return (
    <Flex direction="column">
      {!isLoading ? (
          data?.length > 0 ? (
          <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
            spacing={{ base: "40px", xl: "40px" }}
          >
            {data?.map((video) => (
            <VideoCard video={video} key={video?.id} showName />
          ))}
          </SimpleGrid>
        ) : (
          <Card bg={bgCard} p={4} borderRadius={15} h={400} justifyContent='center' alignItems='center'>
            <Text fontSize={{base:20, md:25}} color='gray' fontWeight='bold'>No Download Available</Text>
          </Card>
        )
      ) : (
        <Box height="50vh" w="100%">
          <Loader />
        </Box>
      )}
      {isFetching && <Loader />}
      {hasMore && !isLoading && <Box ref={ref} style={{ height: "20px" }} />}
    </Flex>
  );
};

export default Downloads;
