import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useDropzone } from "react-dropzone";

function Dropzone(props) {
  const { content, onDrop, accept, disabled, ...rest } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    multiple: false,
    disabled,
  });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      h="max-content"
      minH="100%"
      cursor={disabled ? "not-allowed" : "pointer"}
      {...getRootProps({ className: "dropzone" })}
      {...rest}
    >
      <Input isDisabled={disabled} variant="main" {...getInputProps()} />

      <Button isDisabled={disabled} variant="no-effects">
        {content}
      </Button>
    </Flex>
  );
}

export default Dropzone;
