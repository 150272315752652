import { Flex, Button, useColorModeValue, Box } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import React, { useState } from "react";
import Upload from "components/upload/Upload";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetVerificationVideoQuery } from "store/api/user";
import { nanoid } from "@reduxjs/toolkit";

const VerificationVideo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const textColor = useColorModeValue("#00000075", "#cecece");
  const { data, isLoading, isFetching } = useGetVerificationVideoQuery(
    undefined,
    {
      keepUnusedDataFor: 0,
    }
  );

  return (
    <Flex direction="column">
      {!isLoading || !isFetching ? (
        data?.data?.verification_video_path ? (
          <>
            <Button
              variant="brand"
              marginLeft="auto"
              fontWeight="500"
              mb={5}
              px={5}
              onClick={() => setIsModalOpen(true)}
            >
              Update video
            </Button>
            <Box maxW={500} mx="auto">
              <VideoCard key={nanoid()} video={data?.data} varified={true} />
            </Box>
          </>
        ) : (
          <Flex w="100%" gap="4rem" flexDir="column">
            <p style={{ fontWeight: 700 }}>
              <span style={{ color: textColor }}>
                Please upload verfication video of yourself saying
              </span>
              <br /> I, (name), allow HeyGen to use the footage of me to build a
              HeyGen Avatar for use on the HeyGen platform.
            </p>
            <Button
              variant="brand"
              marginX="auto"
              fontWeight="500"
              mb={5}
              px={5}
              onClick={() => setIsModalOpen(true)}
            >
              Upload video
            </Button>
          </Flex>
        )
      ) : (
        <Box height="50vh" w="100%">
          <Loader />
        </Box>
      )}
      <Modal
        title="Add Verification video"
        isModalOpen={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
      >
        <Upload
          bucket="verification_videos"
          setIsModalOpen={setIsModalOpen}
          description="MP4, MOV, and MKV files are allowed"
          accept="video"
          w="100%"
          h="300px"
        />
      </Modal>
    </Flex>
  );
};

export default VerificationVideo;
