import {
  Button,
  Flex,
  SimpleGrid,
  Box,
  useToast,
  Card,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Upload from "components/upload/Upload";
import Modal from "components/modal/Modal";
import AudioCard from "components/card/AudioCard";
import Loader from "components/loader/Loader";
import { useGetAllFilesQuery } from "store/api/avatar";
import { useDeleteFileMutation } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useSelector } from "react-redux";

const Audios = () => {
  const currentlyPlayingRef = useRef(null);
  const adminDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = adminDetails?.user?.user_metadata?.is_admin;
  const toast = useToast();
  const bgCard = useColorModeValue("#fff", "#434343");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryParams = {
    type: "audio",
  };

  const {
    items: audiosData,
    ref,
    isLoading,
    isFetching,
    loadMore,
    setPage,
  } = useInfiniteScroll(useGetAllFilesQuery, queryParams);

  const [deleteFile] = useDeleteFileMutation();

  const handleDelete = async (id) => {
    setPage(1);
    try {
      await deleteFile({ id, isAdmin }).unwrap();
      toast({
        title: "Audio has been deleted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (err) {
      toast({
        title: "Failed to delete audio",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const menuOptions = [
    { id: 1, title: "Delete", method: handleDelete, color: "red" },
    { id: 2, title: "Edit", method: () => {} },
  ];

  // Function to handle play action in AudioCard
  const handleAudioPlay = (audioRef) => {
    // If another audio is currently playing and it's different from the one being played
    if (
      currentlyPlayingRef.current &&
      currentlyPlayingRef.current !== audioRef
    ) {
      currentlyPlayingRef.current.pause(); // Pause the currently playing audio
      currentlyPlayingRef.current.currentTime = 0; // Reset it to the beginning
    }
    currentlyPlayingRef.current = audioRef; // Set the new audio as the currently playing one
  };

  return (
    <Flex direction="column">
      <Button
        variant="brand"
        marginLeft="auto"
        fontWeight="500"
        mb={5}
        px={5}
        onClick={() => setIsModalOpen(true)}
      >
        Add new
      </Button>
      {audiosData?.length > 0 ? (
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: "40px", xl: "40px" }}
        >
          {audiosData?.map((voice) => (
            <AudioCard
              voice={voice}
              key={voice?.id}
              menuOptions={menuOptions}
              onPlay={handleAudioPlay}
            />
          ))}
        </SimpleGrid>
      ) : (
        !isLoading &&
        !isFetching && (
          <Card
            bg={bgCard}
            p={4}
            borderRadius={15}
            h={400}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              fontSize={{ base: 20, md: 25 }}
              color="gray"
              fontWeight="bold"
            >
              No Audio Available
            </Text>
          </Card>
        )
      )}

      {(isLoading || isFetching) && <Loader />}

      {loadMore && <Box ref={ref} style={{ height: "20px" }} />}

      <Modal
        title="Add New Audio"
        isModalOpen={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
      >
        <Upload
          setIsModalOpen={setIsModalOpen}
          setPage={setPage}
          description="MP3, WAV, AAC, FLAC, and OGG files are allowed"
          accept="audio"
          w="100%"
          h="300px"
        />
      </Modal>
    </Flex>
  );
};

export default Audios;
