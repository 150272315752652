import React from "react";
import {
  Textarea,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Button,
} from "@chakra-ui/react";
import Upload from "./Upload";
import { CgNotes } from "react-icons/cg";

const CustomScriptUpload = ({
  hasCustomScript,
  setHasCustomScript,
  customScriptContent,
  setCustomScriptContent,
  setSelectedFiles,
  isLoading,
  setIsModalOpen,
  hideUpload,
  handleFileUpload,
  isDisabled,
}) => {
  return (
    <>
      {hasCustomScript ? (
        <Textarea
          placeholder="Script..."
          value={customScriptContent}
          onChange={(ev) => setCustomScriptContent(ev.target.value)}
          maxLength={2000}
          rows={10}
          bg="white"
          margin={4}
          width="-webkit-fill-available"
          height="-webkit-fill-available"
          isDisabled={isLoading}
          position="relative"
        />
      ) : (
        <Upload
          setSelectedFiles={setSelectedFiles}
          setIsModalOpen={setIsModalOpen}
          hideUpload={!!hideUpload}
          title="Upload Script"
          uploadIcon={CgNotes}
          description="Only CSV and Docx files are allowed"
          accept="csv"
          w="100%"
          h="300px"
          optional={true}
          disabled={isLoading}
        />
      )}
      <Flex
        direction={{ base: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={5}
        position="relative"
        bottom={2}
      >
        <FormControl display="flex" alignItems="center" position="relative">
          <FormLabel htmlFor="script" mb="0">
            Custom script?
          </FormLabel>
          <Switch
            id="script"
            variant="brand"
            isChecked={hasCustomScript}
            isDisabled={isLoading}
            onChange={(ev) => setHasCustomScript(ev.target.checked)}
          />
        </FormControl>
        {hasCustomScript && (
          <Text zIndex={999} whiteSpace="nowrap">
            {`${Number(customScriptContent.length)} / 2000`}
          </Text>
        )}
      </Flex>
      <Flex direction="row" justifyContent="center" width="100%">
        {handleFileUpload && (
          <Button
            mt={{ base: "20px" }}
            minW="140px"
            variant="brand"
            onClick={handleFileUpload}
            isLoading={isLoading}
            isDisabled={isLoading || isDisabled}
          >
            Upload
          </Button>
        )}
      </Flex>
    </>
  );
};

export default CustomScriptUpload;
