import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const tagApi = createApi({
  reducerPath: "tagApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Tags"],
  endpoints: (builder) => ({
    getAllTags: builder.query({
      query: ({ search }) => `/tags?q=${search}`,
      providesTags: ["Tags"],
    }),
  }),
});

export const { useGetAllTagsQuery } = tagApi;
