import Cookies from "js-cookie";

export const setCookieWithExpiry = (token, expires_at) => {
  const expirationDate = new Date(expires_at * 1000);
  Cookies.set("token", token, { expires: expirationDate });
};

export const getAuthToken = () => {
  return Cookies.get("token");
};

export const removeAuthToken = () => {
  return Cookies.remove("token");
};
