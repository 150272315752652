import React from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";

const FileMenu = ({ menuOptions, id }) => {
  const menuColor = useColorModeValue("#fff", "#434343");
  const menuHoverColor = useColorModeValue("#00000015", "#ffffff15");
  const MenuBorderColor = useColorModeValue("#c7c7c7", "#3c3c3c");
  return (
    <Menu>
      <MenuButton pt="6px" m="0px" onClick={(e) => e.stopPropagation()}>
        <Icon as={BsThreeDotsVertical} w="20px" h="20px" color="black" />
      </MenuButton>
      <MenuList
        p="0px"
        borderRadius="20px"
        border="1px solid"
        borderColor={MenuBorderColor}
        bg={menuColor}
        zIndex={100}
      >
        <Flex flexDirection="column" p="10px" gap="5px">
          {menuOptions?.map((menu) => (
            <MenuItem
              key={menu.id}
              _hover={{ bg: menuHoverColor }}
              _focus={{ bg: menuHoverColor }}
              color={menu.color ?? ""}
              borderRadius="8px"
              px="14px"
              bg="transparent"
              onClick={() => menu.method(id)}
            >
              <Text fontSize="sm" color={menu.color ?? ""}>
                {menu.title}
              </Text>
            </MenuItem>
          ))}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default FileMenu;
