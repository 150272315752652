import {
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAllUsersQuery } from "store/api/user";

const UserDetail = () => {
  const carbg = useColorModeValue("#fff", "#434343");
  const { id } = useParams();
  const buttonBg = useColorModeValue(
    "rgba(0,0,0,0.1)",
    "rgba(255,255,255,0.1)"
  );
  const inputColor = useColorModeValue("#000", "#fff");

  const adminDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = adminDetails?.user?.user_metadata?.is_admin;
  const { data, isLoading, isFetching } = useGetAllUsersQuery({ isAdmin });
  const user = data?.data?.find((user) => user.id === id);
  const [isEditing, setIsEditing] = useState(false);

  if (isLoading || isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!user) {
    return <div>User not found</div>;
  }
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };
  return (
    <Card
      bg={carbg}
      borderRadius={15}
      mt={{ base: 4, md: 5 }}
      mb={{ base: 4, md: 5 }}
      p={{ base: 4, md: 5 }}
      shadow="md"
    >
      <Button
        position="absolute"
        top={5}
        right={5}
        bg={buttonBg}
        onClick={handleEditClick}
        p={3}
        borderRadius="full"
      >
        <MdOutlineEdit size={20} />
      </Button>
      <Flex
        direction={{ base: "column", md: "row" }}
        spacing={5}
        mt={5}
        alignItems="center"
      >
        <Box
          flex="1"
          borderRight={{ base: "none", md: "1px solid lightgray" }}
          p={3}
          textAlign="center"
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width="150px"
            height="150px"
            bg="black"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <FaRegUser color="white" size="60px" />
          </Box>
          <Text fontWeight="bold" mt={3} fontSize={{ base: "lg", md: "xl" }}>
            {user?.name}
          </Text>
          <Text color="gray.500" fontSize={{ base: "sm", md: "md" }}>
            {user?.email}
          </Text>
        </Box>

        <Box flex="2" p={3}>
          <Stack spacing={4}>
            <Flex direction={{ base: "column", md: "row" }} gap={3}>
              <Box w="100%">
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="User name"
                  color={inputColor}
                  value={user?.name}
                  disabled={!isEditing}
                />
              </Box>
              <Box w="100%">
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="User email"
                  color={inputColor}
                  value={user?.email}
                  disabled={!isEditing}
                />
              </Box>
              <Box w="100%">
                <FormLabel>ID</FormLabel>
                <Input
                  placeholder="User id"
                  color={inputColor}
                  disabled
                  value={user?.id}
                />
              </Box>
            </Flex>
            <Flex direction={{ base: "column", md: "row" }} gap={3}>
              <Box w="100%">
                <FormLabel>Admin</FormLabel>
                <Input
                  placeholder="User email"
                  color={inputColor}
                  value={user?.is_admin === true ? "Yes" : "No"}
                  disabled
                />
              </Box>
              <Box w="100%">
                <FormLabel>Number</FormLabel>
                <Input
                  placeholder="User phone number"
                  color={inputColor}
                  disabled={!isEditing}
                  value={
                    user?.phone === !null ? user?.phone : "No phone number"
                  }
                />
              </Box>
            </Flex>

            <Stack spacing={3}>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Created date</FormLabel>
                  <Input
                    placeholder="Created Date"
                    color={inputColor}
                    disabled
                    value={new Date(user?.created_at).toLocaleDateString()}
                  />
                </Box>
                <Box w="100%">
                  <FormLabel>Created Time</FormLabel>
                  <Input
                    placeholder="Created Time"
                    color={inputColor}
                    disabled
                    value={new Date(user?.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  />
                </Box>
              </Flex>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Last Sign In date</FormLabel>
                  <Input
                    placeholder="Created Date"
                    color={inputColor}
                    disabled
                    value={new Date(user?.last_sign_in_at).toLocaleDateString()}
                  />
                </Box>
                <Box w="100%">
                  <FormLabel>Last Sign In Time</FormLabel>
                  <Input
                    placeholder="Created Time"
                    color={inputColor}
                    disabled
                    value={new Date(user?.last_sign_in_at).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  />
                </Box>
              </Flex>
              <Flex gap={3}>
                <Box w="100%">
                  <FormLabel>Updated date</FormLabel>
                  <Input
                    placeholder="Created Date"
                    color={inputColor}
                    disabled
                    value={new Date(user?.updated_at).toLocaleDateString()}
                  />
                </Box>
                <Box w="100%">
                  <FormLabel>Updated Time</FormLabel>
                  <Input
                    placeholder="Updated Time"
                    color={inputColor}
                    disabled
                    value={new Date(user?.updated_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  />
                </Box>
              </Flex>
            </Stack>
          </Stack>
        </Box>
      </Flex>
      <Box flex="1" p={3}>
        <Text fontSize="xl" mb={3} fontWeight={700}>
          Company Details
        </Text>
        <Stack spacing={3}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Company name"
              color={inputColor}
              disabled
              value={user?.company?.name}
            />
          </Box>
          <Box w="100%">
            <FormLabel>ID</FormLabel>
            <Input
              placeholder="Comapny ID"
              color={inputColor}
              disabled
              value={user?.company?.id}
            />
          </Box>
          <Flex gap={3}>
            <Box w="100%">
              <FormLabel>Created Date</FormLabel>
              <Input
                placeholder="Created Date"
                color={inputColor}
                disabled
                value={new Date(user?.company?.created_at).toLocaleDateString()}
              />
            </Box>
            <Box w="100%">
              <FormLabel>Created Time</FormLabel>
              <Input
                placeholder="Created Time"
                color={inputColor}
                disabled
                value={new Date(user?.company?.created_at).toLocaleTimeString(
                  [],
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              />
            </Box>
          </Flex>
        </Stack>
      </Box>
      {isEditing && (
        <Flex gap={3} justifyContent="end">
          <Button variant="brand">Update</Button>
          <Button variant="ghost" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      )}
    </Card>
  );
};

export default UserDetail;
