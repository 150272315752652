import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React from "react";
import { useGetVoicesQuery } from "store/api/heygen";

const AssignedAudio = () => {
  const tableBg = useColorModeValue("#fff", "#434343");
  const cardBg = useColorModeValue("#e9e9e9", "#00000050");
  const { data, isLoading } = useGetVoicesQuery();
  const recentAudioFiles = data?.data
    ?.slice()
    .sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at))
    .slice(0, 5);
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!recentAudioFiles || recentAudioFiles.length === 0) {
    return (
        <Card p={5} bg={tableBg} borderRadius={15} textAlign="start" >
            <Heading as="h2" size="lg" mb={4}>Recently Assigned Audio</Heading>
            <Box display='flex' justifyContent='center' alignItems='center' h={250} color='gray' fontWeight='bold' fontSize={20}>No Audio Available</Box>
        </Card>
    );
}
  return (
    <Card p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recently Assigned Audio
      </Heading>
      <CardBody p={0}>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
          {recentAudioFiles?.map((audioFile, index) => (
            <Box p={5} bg={cardBg} shadow="lg" borderRadius={15} key={index}>
              <Flex gap={3} alignItems="center" mb={3} flexWrap="wrap">
                <Box
                  p="5px 12px"
                  bg="#E56B20"
                  color="white"
                  borderRadius="100%"
                >
                  A
                </Box>
                <Text>{audioFile.name}</Text>
                <Text fontSize={14}>{audioFile?.metadata?.gender}</Text>.
              </Flex>
              <audio src={audioFile?.url} controls style={{ width: "100%" }} />
              <Flex mt={3} gap={3}>
                <Text>Language:</Text>
                <Text>{audioFile?.metadata?.language}</Text>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default AssignedAudio;
