import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";

const Modal = ({ isModalOpen, setIsModalOpen, title, children }) => {
  const modalBackground = useColorModeValue(
    "lightModeBg.100",
    "darkModeBg.100"
  );

  return (
    <ChakraModal
      variant="brand"
      size="xl"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent
        minW={{ sm: "95vw", xl: "50vw" }}
        bg={modalBackground}
        borderRadius={15}
      >
        <ModalHeader pb={0}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
