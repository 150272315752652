import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const heygenApi = createApi({
  reducerPath: "heygenApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["VerificationVideo", "Heygen-Voices", "Heygen-Videos"],
  endpoints: (builder) => ({
    getAvatars: builder.query({
      query: ({ page }) => `/admin/heygen-assets?type=video&page=${page}`,
      providesTags: ["Heygen-Videos"],
    }),
    getVoices: builder.query({
      query: ({ page }) => `/admin/heygen-assets?type=audio&page=${page}`,
      providesTags: ["Heygen-Voices"],
    }),
    assignAsset: builder.mutation({
      query: ({ assetId, body }) => ({
        url: `/admin/assets/${assetId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["VerificationVideo"],
    }),
  }),
});

export const { useGetAvatarsQuery, useGetVoicesQuery, useAssignAssetMutation } =
  heygenApi;
