import { useState, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

// Custom hook for implementing infinite scrolling
export function useInfiniteScroll(query, params) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  // Select user details from Redux store
  const userDetails = useSelector((state) => state.user.userDetails);

  // Determine if the user is an admin based on user details
  const isAdmin = userDetails.user?.user_metadata?.is_admin;

  // Hook to observe if the loading trigger element is in view
  const { ref, inView } = useInView({
    threshold: 1.0, // Trigger when 100% of the element is visible
  });

  // Memoize query parameters to avoid unnecessary recalculations
  const queryParams = useMemo(
    () => ({ ...params, page, isAdmin }),
    [params, page, isAdmin]
  );

  // Fetch data using the provided query function and parameters
  const { data, isLoading, isFetching } = query(queryParams);

  // Check if there are more pages to load based on pagination data
  const hasMore = useMemo(
    () => data?.pagination?.current_page < data?.pagination?.total_pages,
    [data?.pagination]
  );

  // Determine if we can load more items (not loading and there are more items)
  const loadMore = useMemo(() => hasMore && !isLoading, [hasMore, isLoading]);

  // Effect to update items based on fetched data
  useEffect(() => {
    if (data?.data) {
      if (page === 1) {
        // If it's the first page, set the items directly
        setItems(data?.data);
      } else {
        // For subsequent pages, filter out duplicates and append new items
        setItems((prevItems) => {
          const newItems = data.data?.filter(
            (newItem) =>
              !prevItems.some((prevItem) => prevItem.id === newItem.id)
          );
          return [...prevItems, ...newItems];
        });
      }
    }
  }, [data?.data, page]);

  // Effect to load more items when the observed element is in view and increment the page number to fetch the next set of items
  useEffect(() => {
    if (inView && hasMore && !isLoading && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore, isLoading, isFetching]);

  return { items, ref, isLoading, isFetching, hasMore, loadMore, setPage };
}
