import React, { useEffect } from "react";
import { SimpleGrid, useColorModeValue, Icon, Box } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/statistics/Statistics";
import { IoMdDownload } from "react-icons/io";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { BsSoundwave } from "react-icons/bs";
import { useGetAllFilesQuery } from "store/api/avatar";
import { useDispatch, useSelector } from "react-redux";
import { saveAudioCount, saveVideoCount } from "store/slices/avatar";
import RecentAudio from "./components/RecentAudio";
import RecentVideo from "./components/RecentVideo";
import RecentUsers from "./components/RecentUsers";
import { useGetAllUsersQuery } from "store/api/user";
import HygenAssigned from "./components/heygen/HygenAssigned";
import { useLocation } from "react-router-dom";
import { useGetDownloadsFilesQuery } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { saveDownloadCount } from "store/slices/avatar";

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const brandColor = "customThemeColor.500";
  const boxBg = useColorModeValue("#E56B2020", "whiteAlpha.100");
  const adminDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = adminDetails?.user?.user_metadata?.is_admin;
  const { data } = useGetAllUsersQuery({ isAdmin });
  const matchedUserAuth =
    adminDetails?.user?.id && data?.data
      ? data.data.find((user) => user.id === adminDetails.user.id)
      : null;

  // API queries
  const { data: videosData, refetch: refetchVideos } = useGetAllFilesQuery({
    type: "video",
  });
  const videoNumber = videosData?.data ? videosData?.data?.length : 0;

  const { data: audiosData, refetch: refetchAudios } = useGetAllFilesQuery({
    type: "audio",
  });
  const audioNumber = audiosData?.data ? audiosData?.data?.length : 0;

  const { items: DownloadData } = useInfiniteScroll(useGetDownloadsFilesQuery);
  const downloadCount = DownloadData ? DownloadData?.length : 0;
  useEffect(() => {
    if (videosData?.data?.length) {
      dispatch(saveVideoCount(videosData?.data?.length));
    }

    if (audiosData?.data?.length) {
      dispatch(saveAudioCount(audiosData?.data?.length));
    }
    if (downloadCount > 0) {
      dispatch(saveDownloadCount(downloadCount));
    }
  }, [videosData, audiosData, dispatch, downloadCount]);

  useEffect(() => {
    refetchVideos();
    refetchAudios();
  }, [location.pathname, refetchVideos, refetchAudios]);

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={BsSoundwave} color={brandColor} />
              }
            />
          }
          name="Recent Audios"
          value={audioNumber ?? 0}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineOndemandVideo}
                  color={brandColor}
                />
              }
            />
          }
          name="Recent Videos"
          value={videoNumber ?? 0}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={IoMdDownload} color={brandColor} />
              }
            />
          }
          name=" Recent Downloads"
          value={downloadCount ?? 0}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={5}>
        <RecentAudio />
        <RecentVideo />
      </SimpleGrid>
      <Box display={matchedUserAuth?.is_admin === true ? "block" : "none"}>
        <RecentUsers />
      </Box>
      <HygenAssigned />
    </>
  );
};

export default Dashboard;
