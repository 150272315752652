import React, { useState, useEffect } from "react";
import {
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  Flex,
} from "@chakra-ui/react";
import { useDebounceValue } from "usehooks-ts";
import { useGetAllTagsQuery } from "store/api/tag";
import Loader from "components/loader/Loader";

const InputTag = ({
  onTagsChange,
  tagsList,
  suggestionTag,
  setSuggestionTag,
  isDisabled,
}) => {
  const [debouncedInputValue, setInputValue] = useDebounceValue("", 500);
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);

  const { data, isLoading, isFetching } = useGetAllTagsQuery(
    { search: debouncedInputValue },
    {
      skip: debouncedInputValue?.length < 1,
    }
  );

  useEffect(() => {
    if (tagsList?.length) setTags([...tagsList]);
  }, [tagsList]);

  useEffect(() => {
    if (data?.tags) {
      // Use a Map to filter out duplicates based on tag names
      const uniqueTagsMap = new Map();
      data.tags.forEach((tag) => {
        const tagName = tag.startsWith('{"name"') ? JSON.parse(tag) : tag;

        // Check if the tag name already exists in the map
        if (!uniqueTagsMap.has(tagName)) {
          uniqueTagsMap.set(tagName, tag);
        }
      });

      // Convert the map back to an array and set as suggestionTag
      setSuggestionTag(Array.from(uniqueTagsMap.values()));
    }
  }, [data, setSuggestionTag]);

  const formattedTags = suggestionTag
    ?.filter((tag) =>
      tag.toLowerCase().includes(debouncedInputValue?.toLowerCase())
    )
    .map((tag) => {
      if (tag.startsWith('{"name"')) {
        try {
          return { name: JSON.parse(tag) };
        } catch (error) {
          console.error("Error parsing tag:", error);
          return tag; // Fallback to original tag if parsing fails
        }
      }
      return tag;
    });

  const formattedSubmitTag = tags.map((tag) => {
    if (typeof tg === "string") {
      return tag;
    } else if (tag && tag.startsWith('{"name"')) {
      try {
        return JSON.parse(tag);
      } catch (error) {
        console.error("Error parsing tag:", error);
        return tag;
      }
    }
    return tag;
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setTagInput(e.target.value);
  };

  const handleClearInput = () => {
    setTagInput("");
    setInputValue("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && debouncedInputValue?.trim() !== "") {
      const newTag = debouncedInputValue;
      if (!tags.some((tag) => tag === newTag)) {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        onTagsChange(formattedSubmitTag);
      }
      handleClearInput();
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    onTagsChange(updatedTags);
  };

  const handleSelectTag = (tag) => {
    if (tags?.some((t) => t !== tag) || tags.length === 0) {
      const updatedTags = [...tags, tag];
      setTags(updatedTags);
      onTagsChange(updatedTags);
      handleClearInput();
    }
  };

  return (
    <>
      <Flex
        direction="row"
        alignItems="center"
        p={2}
        flexWrap="wrap"
        gap={2}
        border="1px solid"
        borderColor="inherit"
        borderRadius={8}
        minHeight="40px"
        width="-webkit-fill-available"
        _focus={{ borderColor: "#E56B20", boxShadow: "0 0 0 1px #E56B20" }}
      >
        {tags.map((tag, index) => (
          <Tag key={index} size="md" color="white" bg="black.900" isDisabled>
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton
              onClick={() => handleTagRemove(tag)}
              isDisabled={isDisabled}
              cursor={isDisabled ? "not-allowed" : "pointer"}
            />
          </Tag>
        ))}
        <Input
          isDisabled={isDisabled}
          value={tagInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          borderRadius={15}
          color="black"
          width="fit-content"
          border="none"
          h={5}
          pl={2}
          _focus={{ outline: "none", boxShadow: "none" }}
          placeholder="Add tags"
        />
      </Flex>
      {isLoading || isFetching ? (
        <Loader size={"14"} />
      ) : (
        <Wrap mt={2}>
          {formattedTags?.map((tag, index) => (
            <Tag
              key={index}
              size="md"
              borderRadius="full"
              variant="outline"
              colorScheme="green"
              cursor="pointer"
              onClick={() => handleSelectTag(tag)}
            >
              <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Wrap>
      )}
    </>
  );
};

export default InputTag;
