import React from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  ListIcon,
  Stack,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import Loader from "components/loader/Loader";

const AutoComplete = ({
  data,
  placeholder,
  setSelectedValue,
  selectedValue,
  inputValue,
  setInputValue,
  setDebouncedSearch,
  isLoading,
}) => {
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setDebouncedSearch(value);
  };

  const handleItemClick = (item) => {
    setInputValue(item.title);
    setDebouncedSearch(item.title);
    setSelectedValue(item.value);
  };

  const handleRemove = () => {
    setInputValue("");
    setDebouncedSearch("");
    setSelectedValue(null);
  };

  return (
    <Stack gap={0}>
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={inputValue}
          onChange={!selectedValue ? handleInputChange : () => {}}
          color="black.900"
        />
        <InputRightElement>
          {!!selectedValue && (
            <SmallCloseIcon
              cursor="pointer"
              fontSize={20}
              color="black.900"
              onClick={handleRemove}
            />
          )}
        </InputRightElement>
      </InputGroup>

      {data?.length > 0 && inputValue && !selectedValue && (
        <Box
          borderWidth={1}
          borderRadius="md"
          width="100%"
          maxHeight="200px"
          overflowY="auto"
          zIndex={1}
          bg="white"
        >
          {isLoading ? (
            <Loader size="14" />
          ) : (
            <List spacing={1} padding={2}>
              {data?.map((item, index) => (
                <ListItem
                  key={index}
                  padding={2}
                  cursor="pointer"
                  _hover={{ bg: "gray.100" }}
                  onClick={() => handleItemClick(item)}
                >
                  <ListIcon as={FaUser} color="green.500" />
                  {item.title}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default AutoComplete;
