import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { supabase } from "superbase/auth";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import { sidebarLinks as routes } from "routes/urls";
import PropTypes from "prop-types";
import React from "react";
import { FaEthereum } from "react-icons/fa";
import { useRole } from "hooks/useRole";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "store/api/user";
import { removeAuthToken } from "utils/auth";

const AdminNavbarLinks = (props) => {
  const { roleRoutes } = useRole(routes);

  const toast = useToast();
  const { secondary } = props;
  const adminDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = adminDetails?.user?.user_metadata?.is_admin;
  const { data } = useGetAllUsersQuery({ isAdmin });
  let mainMenuBg = useColorModeValue("white", "rgba(255,255,255,0.1)");
  let menuBg = useColorModeValue("lightModeBg.100", "darkModeBg.100");
  let menuListBg = useColorModeValue("secondaryGray.400", "#c7c7c7");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const matchedUser =
    adminDetails?.user?.id && data?.data
      ? data.data.find((user) => user.id === adminDetails.user.id)
      : null;

  const handleLogout = async () => {
    removeAuthToken();
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: error.message || "Logout failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    window.location.reload();
  };

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={mainMenuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: "none", md: "unset" }}>
            {" "}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={roleRoutes} />
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={matchedUser?.name ? matchedUser?.name : "Default User"}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey{" "}
              {matchedUser?.name ? matchedUser?.name : "Default User"}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px" gap="5px">
            <MenuItem
              _hover={{ bg: "secondaryGray.300" }}
              _focus={{ bg: "secondaryGray.300" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              bg={menuListBg}
              onClick={handleLogout}
            >
              <Text fontSize="sm" color="red">
                Log out
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default AdminNavbarLinks;

AdminNavbarLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
