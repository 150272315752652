import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

const useCheckCharacterLength = (maxCharacterLength) => {
  const toast = useToast();

  const handleCheckCharacterLength = useCallback(
    (fileContent) => {
      let isExceeding = false;

      fileContent?.forEach((row) => {
        const message = row.message;
        const charCount = message ? message.replace(/\s/g, "").length : 0; // Count characters excluding spaces

        if (charCount > maxCharacterLength) {
          toast({
            title: `Row exceeds ${maxCharacterLength} characters (excluding spaces)`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          isExceeding = true;
        }
      });

      return isExceeding;
    },
    [maxCharacterLength, toast]
  );

  return handleCheckCharacterLength;
};

export default useCheckCharacterLength;
