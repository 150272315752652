import { useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import * as XLSX from "xlsx";
import mammoth from "mammoth";
import { textFileExtensions } from "constants/fileTypes";

const useFileProcessor = (
  selectedFiles,
  checkCharacterLength,
  setCsvData,
  setFileContentPreview,
  setSelectedFiles,
  setAcceptDoc
) => {
  const toast = useToast();

  const readTextFile = useCallback(
    (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result;
        setFileContentPreview(content);
        setCsvData(null);
      };
      reader.readAsText(file);
    },
    [setCsvData, setFileContentPreview]
  );

  const readWordFile = useCallback(
    async (file) => {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      setFileContentPreview(result.value);
      setCsvData(null);
    },
    [setCsvData, setFileContentPreview]
  );

  const handleFileChange = useCallback(
    async (file, fileExtension) => {
      if (fileExtension === "txt") {
        readTextFile(file);
      } else if (fileExtension === "doc" || fileExtension === "docx") {
        await readWordFile(file);
      } else {
        toast({
          title: "Unsupported file type!",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    },
    [toast, readTextFile, readWordFile]
  );

  useEffect(() => {
    const file = selectedFiles?.csv?.file;
    if (file) {
      const fileExtension = file?.name.split(".").pop()?.toLowerCase();
      if (textFileExtensions.includes(fileExtension)) {
        setAcceptDoc("doc");
        handleFileChange(file, fileExtension);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target?.result;
          let firstColumnData = [];

          if (fileExtension === "csv") {
            const parsedData = XLSX.read(data, { type: "string" });
            const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            firstColumnData = jsonData.map((row) => ({ message: row[0] }));
          } else if (fileExtension === "xlsx") {
            const parsedData = XLSX.read(new Uint8Array(data), {
              type: "array",
            });
            const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            firstColumnData = jsonData.map((row) => ({ message: row[0] }));
          }

          const isExceeding = checkCharacterLength(firstColumnData);
          if (isExceeding) {
            setCsvData(null);
            setSelectedFiles((prevState) => ({ ...prevState, csv: null }));
          } else {
            setCsvData(firstColumnData);
            setFileContentPreview(null);
          }
        };

        if (fileExtension === "csv") {
          reader.readAsText(file);
        } else if (fileExtension === "xlsx") {
          reader.readAsArrayBuffer(file);
        }
      }
    }
  }, [
    selectedFiles.csv,
    handleFileChange,
    checkCharacterLength,
    setCsvData,
    setFileContentPreview,
    setSelectedFiles,
    setAcceptDoc,
  ]);
};

export default useFileProcessor;
