import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery, appendAdminPath } from "../utils";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Users", "VerificationVideo"],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ search, isAdmin }) => {
        const adminPath = appendAdminPath(isAdmin);
        return {
          url: `${adminPath}/users`,
          params: { q: search },
        };
      },
      providesTags: ["Users"],
    }),
    getVerificationVideo: builder.query({
      query: () => ({
        url: `/users/any-id-here`,
      }),
      providesTags: ["VerificationVideo"],
    }),
    updateVerificationVideo: builder.mutation({
      query: ({ body }) => ({
        url: `/users/any-id-here`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["VerificationVideo"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetVerificationVideoQuery,
  useUpdateVerificationVideoMutation,
} = userApi;
