import {
  Button,
  Flex,
  SimpleGrid,
  Modal,
  useColorModeValue,
  Box,
  useToast,
  Text,
  Card,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Upload from "components/upload/Upload";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetAllFilesQuery } from "store/api/avatar";
import { useDeleteFileMutation } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const Videos = () => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bgCard = useColorModeValue("#fff", "#323232");

  const queryParams = {
    type: "video",
  };

  const {
    items: fetchedVideos,
    ref,
    isLoading,
    isFetching,
    loadMore,
    setPage,
  } = useInfiniteScroll(useGetAllFilesQuery, queryParams);

  const [deleteFile] = useDeleteFileMutation();

  const handleDelete = async (id) => {
    setPage(1);
    try {
      await deleteFile({ id }).unwrap();
      toast({
        title: "Video has been deleted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (err) {
      toast({
        title: "Failed to delete video",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const menuOptions = [
    { id: 1, title: "Delete", method: handleDelete, color: "red" },
    { id: 2, title: "Edit", method: () => {} },
  ];

  return (
    <Flex direction="column">
      <Button
        variant="brand"
        marginLeft="auto"
        fontWeight="500"
        mb={5}
        px={5}
        onClick={() => setIsModalOpen(true)}
      >
        Add new
      </Button>

      {fetchedVideos?.length > 0 ? (
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: "40px", xl: "40px" }}
        >
          {fetchedVideos?.map((video, index) => (
            <VideoCard
              video={video}
              key={video?.id}
              menuOptions={video?.is_request_asset === true ? [] : menuOptions}
              showMenu={video?.is_request_asset === false ? true : false}
              showName
            />
          ))}
        </SimpleGrid>
      ) : (
        !isLoading &&
        !isFetching && (
          <Card
            bg={bgCard}
            p={4}
            borderRadius={15}
            h={400}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              fontSize={{ base: 20, md: 25 }}
              color="gray"
              fontWeight="bold"
            >
              No Video Available
            </Text>
          </Card>
        )
      )}

      {(isLoading || isFetching) && <Loader />}

      {loadMore && <Box ref={ref} style={{ height: "20px" }} />}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent maxW="700px" borderRadius={15} bg={bgCard}>
          <ModalHeader pb={0}>Add New Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="24px">
            <Upload
              setIsModalOpen={setIsModalOpen}
              setPage={setPage}
              description="MP4, MOV, MKV, and WEBM files are allowed"
              accept="video"
              w="100%"
              h="300px"
              pt={0}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Videos;
