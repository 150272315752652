import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdUpload } from "react-icons/md";
import { fileTypes } from "constants/fileTypes";
import { bytesToMB } from "utils/bytesToMB";
import Dropzone from "./Dropzone";
import { uploadFileWithProgress } from "superbase/apis";
import { useSelector } from "react-redux";
import { fileUpload } from "store/constants";
import { useUploadFileMutation } from "store/api/avatar";
import { useUpdateVerificationVideoMutation } from "store/api/user";
import Loader from "components/loader/Loader";
import Truncate from "components/Truncate/Truncate";

export default function Upload(props) {
  const {
    title,
    setIsModalOpen,
    used,
    total,
    accept,
    bucket,
    description,
    uploadIcon,
    setSelectedFiles,
    hideUpload,
    onSuccess,
    handleFileUpload,
    uploadLoading,
    acceptFile,
    optional,
    disabled,
    setPage,
    ...rest
  } = props;
  const toast = useToast();
  const userDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = userDetails?.user?.user_metadata?.is_admin;
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("gray.400", "white.300");
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const textColorPrimary = useColorModeValue(
    "gray.500",
    "rgb(255, 255, 255, 0.3)"
  );
  const textColorSecondary = useColorModeValue("gray.700", "#fff");

  const [uploadFile, { isLoading, isFetching, isSuccess }] =
    useUploadFileMutation();
  const [
    updateVerificationVideo,
    {
      isLoading: isUpdateLoading,
      isFetching: isUpdateFetching,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateVerificationVideoMutation();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      if (setIsModalOpen) setIsModalOpen(false);
      toast({
        title: "File has been uploaded successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      if (onSuccess) onSuccess(); // Call the success callback
    }
  }, [isSuccess, isUpdateSuccess, setIsModalOpen, toast, onSuccess]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      let fileData = {
        file,
        path: null,
        preview: URL.createObjectURL(file),
        sizeMB: bytesToMB(file.size),
      };
      setSelectedFile(fileData);
      if (setSelectedFiles) {
        setSelectedFiles((prevState) => ({ ...prevState, [accept]: fileData }));
      }
    },
    [setSelectedFiles, accept]
  );

  const handleUpload = async () => {
    if (setPage) setPage(1);
    setLoadingFile(true);
    try {
      const upload = await uploadFileWithProgress(
        selectedFile.file,
        userDetails?.user?.id,
        bucket ?? fileUpload[`${accept}`],
        userDetails?.user?.company_id ?? "dummy-co"
      );
      if (!upload) {
        throw new Error("Upload failed");
      }

      const body = {
        path: upload?.path,
        type: accept,
        bucket: bucket ?? `${accept}-files`,
        name: `${accept}-${new Date().getTime()}`,
        ...(isAdmin && { user_id: userDetails?.user?.id }),
      };

      if (bucket === "verification_videos") {
        await updateVerificationVideo({ body });
      } else {
        await uploadFile({ body, isAdmin });
      }
    } catch (error) {
      setHasError(true);
      toast({
        title: "Error uploading file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingFile(false);
    }
  };

  const fileType = useMemo(() => fileTypes[accept], [accept]);

  const loading = useMemo(
    () =>
      isLoading ||
      isFetching ||
      isUpdateLoading ||
      isUpdateFetching ||
      loadingFile ||
      uploadLoading,
    [
      isLoading,
      isFetching,
      isUpdateLoading,
      isUpdateFetching,
      loadingFile,
      uploadLoading,
    ]
  );

  return (
    <Card {...rest} align="center" p="20px" minH={"400px"}>
      <Flex h="100%" direction={{ base: "column" }}>
        {!loading ? (
          <Dropzone
            accept={fileType}
            onDrop={onDrop}
            disabled={disabled}
            content={
              !selectedFile || hasError ? (
                <Box>
                  <Icon
                    as={uploadIcon ?? MdUpload}
                    w="80px"
                    h="80px"
                    color={brandColor}
                    mb="5px"
                  />
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      {title ?? "Upload Files"}
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={optional ? textColor : "red"}
                      >
                        {optional ? "(Optional)" : "(Required)"}
                      </Text>
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    textAlign="center"
                    whiteSpace="normal"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="300px"
                    mx="auto"
                  >
                    {description}
                  </Text>
                </Box>
              ) : (
                <Flex direction="column" gap="10px">
                  <Box>
                    <Text
                      fontWeight="500"
                      color={textColorPrimary}
                      fontSize="sm"
                    >
                      File name
                    </Text>
                    <Text
                      color={textColorSecondary}
                      px={2}
                      py={0.5}
                      borderRadius="50px"
                      fontWeight="400"
                      fontSize="sm"
                    >
                      <Truncate text={selectedFile?.file?.name} length={20} />
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight="500"
                      color={textColorPrimary}
                      fontSize="sm"
                    >
                      Size
                    </Text>
                    <Text
                      color={textColorSecondary}
                      px={2}
                      py={0.5}
                      borderRadius="50px"
                      fontWeight="400"
                      fontSize="sm"
                    >
                      <Truncate text={selectedFile?.sizeMB} length={20} />
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight="500"
                      color={textColorPrimary}
                      fontSize="sm"
                    >
                      Type
                    </Text>
                    <Text
                      color={textColorSecondary}
                      px={2}
                      py={0.5}
                      borderRadius="50px"
                      fontWeight="400"
                      fontSize="sm"
                    >
                      <Truncate text={selectedFile?.file?.type} length={20} />
                    </Text>
                  </Box>
                </Flex>
              )
            }
          />
        ) : (
          <Box height="30vh" w="100%">
            <Loader />
          </Box>
        )}
      </Flex>
      {props.children} {/* Render children here */}
      {!!selectedFile && !hideUpload && (
        <Flex margin="auto">
          <Button
            me="100%"
            w="140px"
            minW="140px"
            mt={{ base: "20px" }}
            bg="black"
            color="white"
            _hover={{ color: "black", bg: "lightgray" }}
            fontWeight="500"
            onClick={handleFileUpload ?? handleUpload}
            isLoading={loading}
            isDisabled={loading}
          >
            Upload
          </Button>
        </Flex>
      )}
    </Card>
  );
}
