import { mode } from "@chakra-ui/theme-tools";
import lightModeImage from "assets/img/layout/background-light-theme.png";
import darkModeImage from "assets/img/layout/background-dark-theme.jpg";

export const globalStyles = {
  colors: {
    brand: {
      100: "#E9E3FF",
      200: "#422AFB",
      300: "#422AFB",
      400: "#000000",
      500: "#000000",
      600: "#3311DB",
      700: "#02044A",
      800: "#190793",
      900: "#c7c7c7",
    },
    brandScheme: {
      100: "#E9E3FF",
      200: "#7551FF",
      300: "#7551FF",
      400: "#000000",
      500: "#000000",
      600: "#3311DB",
      700: "#02044A",
      800: "#190793",
      900: "#02044A",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    gray: {
      100: "#FAFCFE",
    },
    black: {
      900: "#000000",
    },
    customThemeColor: {
      500: "#E56B20",
    },
    lightModeBg: {
      100: "#FFFFFF",
      200: "#f5f5f5",
    },
    darkModeBg: {
      100: "#323232",
      200: "#434343",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("#f5f5f5", "#434343")(props),
        fontFamily: "Inter",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.300",
      },
      html: {
        fontFamily: "Inter",
        backgroundImage: mode(
          `url(${lightModeImage})`,
          `url(${darkModeImage})`
        )(props),
        backgroundSize: "cover", // Ensure the image covers the entire background
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed", // Optional: keeps the background fixed while scrolling
      },
    }),
  },
};
