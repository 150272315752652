import { useCallback } from "react";
import * as XLSX from "xlsx";
import mammoth from "mammoth";

const useFileFetcher = (setFileContent, setCsvData, textFileExtensions) => {
  const fetchTextFile = useCallback(
    async (fileUrl) => {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Failed to fetch text file");
      const text = await response.text();
      setFileContent(text);
      setCsvData(null);
    },
    [setFileContent, setCsvData]
  );

  const fetchWordFile = useCallback(
    async (fileUrl) => {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Failed to fetch Word file");
      const arrayBuffer = await response.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      setFileContent(result.value);
      setCsvData(null);
    },
    [setFileContent, setCsvData]
  );

  const handleFileFromUrl = useCallback(
    async (fileUrl) => {
      const fileName = fileUrl.split("/").pop()?.split("?")[0];
      const fileExtension = fileName?.split(".").pop()?.toLowerCase();

      if (textFileExtensions.includes(fileExtension)) {
        if (fileExtension === "txt") {
          await fetchTextFile(fileUrl);
        } else if (fileExtension === "doc" || fileExtension === "docx") {
          await fetchWordFile(fileUrl);
        } else {
          setFileContent("Unsupported file type");
        }
      } else {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target?.result;
            let firstColumnData = [];

            if (fileExtension === "csv") {
              const parsedData = XLSX.read(data, { type: "string" });
              const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
              const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              firstColumnData = jsonData.map((row) => ({ message: row[0] }));
            } else if (fileExtension === "xlsx") {
              const parsedData = XLSX.read(new Uint8Array(data), {
                type: "array",
              });
              const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
              const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              firstColumnData = jsonData.map((row) => ({ message: row[0] }));
            }
            setFileContent(null);
            setCsvData(firstColumnData);
          };

          if (fileExtension === "csv") {
            reader.readAsText(blob);
          } else if (fileExtension === "xlsx") {
            reader.readAsArrayBuffer(blob);
          }
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      }
    },
    [
      fetchTextFile,
      fetchWordFile,
      setCsvData,
      setFileContent,
      textFileExtensions,
    ]
  );

  return { handleFileFromUrl };
};

export default useFileFetcher;
