import { Box, Input, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const InputField = ({ name, type, placeholder, value, onChange, description = false}) => {
    const textColor = useColorModeValue('#000', '#fff')
  return (
    <Box display='flex' flexDirection='column'>
      <Text mb={1}>{name}</Text>
      {description ? 
      <Textarea placeholder={placeholder} value={value}  _focus={{borderColor: '#E56B20', boxShadow:'0 0 0 1px #E56B20'}} resize='none' minH='150px' borderRadius={15} onChange={onChange}/>
      :
      <Input 
        type={type} 
        placeholder={placeholder} 
        value={value} 
        borderRadius={15}
        _focus={{borderColor: '#E56B20', boxShadow:'0 0 0 1px #E56B20'}}
        color={textColor}
        onChange={onChange} // Add onChange handler here
      />
      }
    </Box>
  );
};

export default InputField;
