import {
  Button,
  Flex,
  SimpleGrid,
  Box,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import React, { useMemo, useRef, useState } from "react";
import AudioCard from "components/card/AudioCard";
import Loader from "components/loader/Loader";
import { useGetVoicesQuery, useAssignAssetMutation } from "store/api/heygen";
import { useGetAllUsersQuery } from "store/api/user";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import AutoComplete from "components/autocomplete";
import { useDebounceValue } from "usehooks-ts";

const HeygenAudios = () => {
  const toast = useToast();
  const currentlyPlayingRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue("", 500);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const {
    items: audiosData,
    ref,
    isLoading,
    isFetching,
    loadMore,
  } = useInfiniteScroll(useGetVoicesQuery);

  const { data: users, isFetching: usersFetching } = useGetAllUsersQuery(
    {
      search: debouncedSearch,
      isAdmin: true,
    },
    {
      skip: selectedUser,
    }
  );

  const [assignAsset, { isLoading: assignLoading }] = useAssignAssetMutation();

  const handleCloseModal = () => {
    setIsAssignModalOpen(false);
    setInputValue("");
    setSelectedUser(null);
  };

  const handleAssign = async () => {
    try {
      await assignAsset({
        assetId: selectedAudio,
        body: { user_id: selectedUser },
      });

      toast({
        title: "Audio has been assigned successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch {
      toast({
        title: "Failed to assign audio",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    handleCloseModal();
  };

  const handleSelectAudio = (id) => {
    setIsAssignModalOpen(true);
    setSelectedAudio(id);
  };

  // Function to handle play action in AudioCard
  const handleAudioPlay = (audioRef) => {
    // If another audio is currently playing and it's different from the one being played
    if (
      currentlyPlayingRef.current &&
      currentlyPlayingRef.current !== audioRef
    ) {
      currentlyPlayingRef.current.pause(); // Pause the currently playing audio
      currentlyPlayingRef.current.currentTime = 0; // Reset it to the beginning
    }
    currentlyPlayingRef.current = audioRef; // Set the new audio as the currently playing one
  };

  const menuOptions = [
    { id: 1, title: "Assign", method: (id) => handleSelectAudio(id) },
  ];

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ title: user?.name, value: user?.id })),
    [users]
  );

  return (
    <Flex direction="column">
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        {audiosData?.map((voice) => (
          <AudioCard
            voice={voice}
            key={voice?.voice_id}
            menuOptions={menuOptions}
            onPlay={handleAudioPlay}
          />
        ))}
      </SimpleGrid>

      {(isLoading || isFetching) && <Loader />}
      {loadMore && <Box ref={ref} style={{ height: "20px" }} />}
      <Modal
        title="Assign Audio to User"
        isModalOpen={isAssignModalOpen}
        setIsModalOpen={handleCloseModal}
      >
        <Stack py={4} gap={0}>
          <FormLabel>Select User</FormLabel>
          <AutoComplete
            data={userOptions ?? []}
            placeholder="Search user"
            setSelectedValue={setSelectedUser}
            selectedValue={selectedUser}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setDebouncedSearch={setDebouncedSearch}
            isLoading={usersFetching}
          />
          <Stack direction="row" justifyContent="center">
            <Button
              mt={6}
              variant="brand"
              onClick={handleAssign}
              isLoading={assignLoading}
              isDisabled={assignLoading || !selectedUser}
            >
              Assign
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Flex>
  );
};

export default HeygenAudios;
