import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import Card from "components/card/Card";
import Loader from "components/loader/Loader";
import RowItem from "components/tables/RowItem";

const CustomTable = ({
  columnsData,
  tableData,
  tableTitle,
  currentPage,
  setCurrentPage,
  totalItems,
  pageSize = 20,
  hidePagination,
  isLoading,
  height,
}) => {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: { pageIndex, pageSize },
    // initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const updatePage = (current) => {
    setCurrentPage?.(current);
  };
  const heading = useColorModeValue("secondaryGray.900", "secondaryGray.300");
  const cardBg = useColorModeValue("white", "#323232");

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      bg={cardBg}
      height={height ?? "auto"}
      overflowX={{ sm: "scroll", lg: "hidden" }}
      overflowY="scroll"
      style={{ scrollbarWidth: "none" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableTitle}
        </Text>
      </Flex>
      {!tableData.length || isLoading ? (
        <Box mt={4} mb={8}>
          <Loader />
        </Box>
      ) : (
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          layout="fixed"
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      color={heading}
                    >
                      <RowItem
                        value={cell.value}
                        color="white"
                        shouldCopy={!!cell.column.copy}
                        isLink={!!cell.column.link}
                        linkDisplayText={cell.column.linkText}
                        haveTooltip={!!cell.column.tooltip}
                        preWrap={!!cell.column.preWrap}
                      >
                        {cell.render("Cell")}
                      </RowItem>
                      {!!cell.column.divider && (
                        <Divider
                          orientation="horizontal"
                          width="100%"
                          bg="black.900"
                          mt={2}
                        />
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}

      {!hidePagination && (
        <Flex direction="row" justifyContent="center">
          <Pagination
            current={currentPage}
            total={totalItems ?? 0}
            onChange={updatePage}
          />
        </Flex>
      )}
    </Card>
  );
};
export default CustomTable;
