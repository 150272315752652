import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useGetAllUsersQuery } from "store/api/user";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Users = () => {
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const navigate = useNavigate();

  const adminDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = adminDetails?.user?.user_metadata?.is_admin;

  const { data, isLoading, isFetching } = useGetAllUsersQuery({ isAdmin });
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Company",
      accessor: "company",
      Cell: ({ value }) => (value ? value.name : "No user"),
    },
    {
      Header: "Last Sign In At",
      accessor: "last_sign_in_at",
      Cell: ({ value }) => <> {format(new Date(value), "dd/MM/yyyy")}</>,
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      id: "created_date",
      Cell: ({ value }) => <> {new Date(value).toLocaleDateString()}</>,
    },
    {
      Header: "Created Time",
      accessor: "created_at",
      id: "created_time",
      Cell: ({ value }) => (
        <>
          {" "}
          {new Date(value).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </>
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Box>
          <Text
            cursor="pointer"
            color="blue.400"
            onClick={() => navigate(`/users/${row.original.id}`)}
          >
            View More
          </Text>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        {data?.data?.length ? (
          <CustomTable
            columnsData={columns}
            tableData={data?.data ?? []}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={data?.totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Loader />
        )}
      </SimpleGrid>
    </Box>
  );
};

export default Users;
