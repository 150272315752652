import {
  SimpleGrid,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useGetAllCompaniesQuery } from "store/api/company";
import { useCallback, useEffect } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useCreateCompanyMutation } from "store/api/company";
import { useNavigate } from "react-router-dom";

const Companies = () => {
  const toast = useToast();
  const pageSize = 50;
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllCompaniesQuery();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();

  const textColor = useColorModeValue("black.900", "white");
  const createButton = useColorModeValue("#000", "#3C3C3C");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("black.900", "red.400");

  const [createCompany, { isLoading: createLoading }] =
    useCreateCompanyMutation();

  const handleClick = () => setShow(!show);

  const handleCreateCompany = useCallback(
    async (data) => {
      createCompany({ data }).then((result) => {
        if (result.error) {
          toast({
            title: "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Company has been created successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      });
      setIsModalOpen(false);
      reset();
    },
    [createCompany, toast, reset]
  );

  useEffect(() => {
    const handleKeyDown = (ev) => {
      if (ev.key === "Enter") {
        handleSubmit(handleCreateCompany)(ev);
      }
    };

    // Add event listener for the 'Enter' key
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSubmit, handleCreateCompany]);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },

    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Users",
      accessor: "users",
      Cell: ({ value }) => value ? value.map(user => user.name).join(", ") : "No users",
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      id: "created_date",
      Cell: ({ value }) => <> {new Date(value).toLocaleDateString()}</>,
    },
    {
      Header: "Created Time",
      accessor: "created_at",
      id: "created_time",
      Cell: ({ value }) => <> {new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>,
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Box>
          <Text cursor="pointer" onClick={() => navigate(`/companies/${row.original.id}`)}>View More</Text>
        </Box>
      ),
    },
  ];

  const modalBackground = useColorModeValue(
    "lightModeBg.100",
    "darkModeBg.100"
  );

  return (
    <Flex direction="column">
      <Button
        variant="brand"
        marginLeft="auto"
        fontWeight="500"
        mb={5}
        px={5}
        onClick={() => setIsModalOpen(true)}
      >
        Add new
      </Button>
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        {data?.data?.length ? (
          <CustomTable
            columnsData={columns}
            tableData={data?.data ?? []}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={data?.totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Loader />
        )}
      </SimpleGrid>
      <Modal
        variant="brand"
        size="md"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent
          minW={{ sm: "95vw", xl: "30vw" }}
          bg={modalBackground}
          borderRadius={15}
        >
          <ModalHeader pb={0}>Add new company</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <FormControl
                as="form"
                onSubmit={handleSubmit(handleCreateCompany)}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  {...register("email")}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@simmmple.com"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  color={textColor}
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired
                    {...register("password")}
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    color={textColor}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>

                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Company<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  {...register("name")}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="e.g. Google"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  color={textColor}
                />

                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Phone<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  {...register("phone")}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="e.g. +1-212-456-7890"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  color={textColor}
                />

                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Username<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  {...register("username")}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="e.g. Mike Tyson"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  color={textColor}
                />
                <Button
                  fontSize="sm"
                  color="white"
                  variant="brand"
                  bg={createButton}
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  disabled={isSubmitting || createLoading}
                  isLoading={isSubmitting || createLoading}
                >
                  Create
                </Button>
              </FormControl>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Companies;
