import { Card, Box, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const CsvPreview = ({ tableData, height }) => {
    const cardBg = useColorModeValue("white", "#323232");

    const formatText = (message) => {
        if (typeof message !== 'string') return { __html: '' };  

        let formattedText = message
            .replace(/\n/g, '<br />') 
            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') 
            .replace(/\*(.*?)\*/g, '<i>$1</i>') 
            .replace(/__(.*?)__/g, '<u>$1</u>') 
            .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>'); 

        return { __html: formattedText };
    };

    return (
        <Card
            direction="column"
            w="100%"
            px="16px"
            p={5}
            bg={cardBg}
            height={height ?? "auto"}
            overflowX="auto"
            style={{ scrollbarWidth: 'none' }}
            borderRadius={15}
        >
            {tableData && Array.isArray(tableData) ? (
                tableData.map((row, rowIndex) => (
                    <Box
                        key={rowIndex}
                        display="flex"
                        flexDirection="row"
                        borderBottom="1px solid gray"
                    >
                        {Object.values(row).map((cell, cellIndex) => (
                            <Box
                                key={cellIndex}
                                p="8px"
                                flex="1"
                                borderTop={rowIndex === 0 && cellIndex === 0 ? '1px solid gray' : 'none'} 
                                borderLeft="1px solid gray"
                                borderRight="1px solid gray"
                                dangerouslySetInnerHTML={formatText(cell)}  
                            />
                        ))}
                    </Box>
                ))
            ) : (
                <Box
                    p="16px"
                    dangerouslySetInnerHTML={formatText(tableData)}
                />
            )}
        </Card>
    );
}

export default CsvPreview;
