import { Box, Icon, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import FileMenu from "components/menu/FileMenu";
import { IoIosArrowUp } from "react-icons/io";
import React from "react";
import { useNavigate } from "react-router-dom";
import { urls } from "routes/urls";

const AvatarCard = ({ menuOptions, avatar, height }) => {
  const navigate = useNavigate();
  return (
    <Card
      bg={""}
      minHeight="200px"
      maxHeight={height}
      p={0}
      key={avatar.id}
      cursor="pointer"
      onClick={() => navigate(`${urls.requestAvatarDetails(avatar.key)}`)}
    >
      <Box
        position="relative"
        width="100%"
        height="400px"
        borderRadius="10px"
        backgroundImage={`url(${avatar.image})`}
        filter="blur(1px)"
        zIndex={-1}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display='flex'
        justifyContent="center"
        alignItems='center'
        bg='#ffffff20'
        w='100%'
        h='100%'
        borderRadius={15}
      >
        <Text fontSize="4xl" fontWeight="bold" color="white">
          {avatar.title}
        </Text>
      </Box>

      <Box
        style={{
          position: "absolute",
          left: "10px",
          bottom: "10px",
          boxShadow: 10,
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FileMenu menuOptions={menuOptions} id={avatar.id} />
      </Box>

      <Box
        style={{
          position: "absolute",
          right: "10px",
          bottom: "10px",
          boxShadow: 10,
          height: "50px",
          width: "50px",
          background: "grey",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon as={IoIosArrowUp} w="24px" h="24px" color={"white"} />
      </Box>
    </Card>
  );
};

export default AvatarCard;
