import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery, appendAdminPath } from "../utils";

export const avatarApi = createApi({
  reducerPath: "avatarApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Assets", "Avatar", "Heygen-Videos", "Heygen-Voices"],
  endpoints: (builder) => ({
    getDownloadsFiles: builder.query({
      query: ({ page }) => `/downloads?page=${page}`,
      providesTags: ["Assets"],
    }),
    getAllFiles: builder.query({
      query: ({ type, isAdmin, page }) =>
        `${appendAdminPath(isAdmin)}/assets?type=${type}&page=${page}`,
      providesTags: ["Assets"],
    }),
    uploadFile: builder.mutation({
      query: ({ body, isAdmin }) => ({
        url: `${appendAdminPath(isAdmin)}/assets`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Assets", "Heygen-Videos", "Heygen-Voices"],
    }),
    deleteFile: builder.mutation({
      query: ({ id, isAdmin }) => ({
        url: `${appendAdminPath(isAdmin)}/assets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Assets"],
    }),
    createAavatarRequest: builder.mutation({
      query: ({ body }) => ({
        url: `/avatar-requests`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Avatar"],
    }),
    getAvatarDetails: builder.query({
      query: ({ id, isAdmin }) =>
        `${appendAdminPath(isAdmin)}/avatar-requests/${id}`,
      providesTags: ["Avatar"],
    }),
    updateAvatarRequest: builder.mutation({
      query: ({ body, id, isAdmin }) => ({
        url: `${appendAdminPath(isAdmin)}/avatar-requests/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Avatar"],
    }),
    listOfSubmittedAvatars: builder.query({
      query: ({ isAdmin }) => `${appendAdminPath(isAdmin)}/avatar-requests`,
      providesTags: ["Avatar"],
    }),
  }),
});

export const {
  useGetDownloadsFilesQuery,
  useListOfSubmittedAvatarsQuery,
  useUploadFileMutation,
  useGetAllFilesQuery,
  useDeleteFileMutation,
  useUpdateAvatarRequestMutation,
  useCreateAavatarRequestMutation,
  useGetAvatarDetailsQuery,
} = avatarApi;
