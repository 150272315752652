import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useJwt } from "react-jwt";
import { getAuthToken, removeAuthToken } from "utils/auth";
import AdminLayout from "layouts/admin";
import React from "react";
import { urls } from "routes/urls";
import { roles } from "routes/roles";
import { useSelector } from "react-redux";
import { supabase } from "superbase/auth";
import { useToast } from "@chakra-ui/react";

const Protected = () => {
  const toast = useToast();
  const userRole = useSelector((state) => state.user.userRole);
  const location = useLocation();
  const { isExpired } = useJwt(getAuthToken() ?? "");

  const notAllowedRoutes = roles[userRole]?.notAllowedRoutes ?? [];

  const handleLogout = async () => {
    removeAuthToken();
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: error.message || "Logout failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  if (!getAuthToken() || isExpired) {
    handleLogout();
    return <Navigate to={urls.signIn} />;
  }

  if (notAllowedRoutes.includes(location.pathname)) {
    return <Navigate to={urls.dashboard} />; // Redirect to dashboard or any other page if access is denied
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default Protected;
