import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useToast,
  Text,
  Link,
  Card,
  useColorModeValue,
  Textarea,
  Tooltip,
  Input,
} from "@chakra-ui/react";
import { uploadFileWithProgress } from "superbase/apis";
import Upload from "components/upload/Upload";
import { fileUpload } from "store/constants";
import { GiSoundWaves } from "react-icons/gi";
import { FaVideo } from "react-icons/fa";
import React, { useState } from "react";
import { useCreateAavatarRequestMutation } from "store/api/avatar";
import { useNavigate, useParams } from "react-router-dom";
import { urls } from "routes/urls";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import CsvPreview from "./components/CsvPreview";
import { isEmpty } from "lodash";
import { createFileObject } from "utils/createTextFileObject";
import useCheckCharacterLength from "hooks/useCSVCharacterCount";
import useFileProcessor from "hooks/useFileProcessor";
import CustomScriptUpload from "components/upload/CustomScriptUpload";
import InputTag from "components/inputTags/InputTag";
import InputField from "./components/InputField";

const AvatarDetails = () => {
  const maxCharacterLength = 2000;
  const checkCharacterLength = useCheckCharacterLength(maxCharacterLength);
  const userDetails = useSelector((state) => state.user.userDetails);
  const navigate = useNavigate();
  const toast = useToast();
  const { avatarType } = useParams();
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [fileContentPreview, setFileContentPreview] = useState(null);
  const [acceptDoc, setAcceptDoc] = useState();
  const [hasCustomScript, setHasCustomScript] = useState(false);
  const [customScriptContent, setCustomScriptContent] = useState("");
  const [tags, setTags] = useState([]);
  const [suggestionTag, setSuggestionTag] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    audio: {},
    video: {},
    csv: {},
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  useFileProcessor(
    selectedFiles,
    checkCharacterLength,
    setCsvData,
    setFileContentPreview,
    setSelectedFiles,
    setAcceptDoc
  );

  const [avatarRequest, { isLoading: avatarLoading }] =
    useCreateAavatarRequestMutation();
  const cardBg = useColorModeValue("#fff", "#434343");
  const toolTipBg = useColorModeValue("white", "#434343");
  const textColor = useColorModeValue("#000", "#fff");

  const handleUpload = async () => {
    setLoading(true);
    let textFileObject = null;
    if (hasCustomScript && customScriptContent) {
      textFileObject = createFileObject(customScriptContent);
    }
    const uploadVideo = await uploadFileWithProgress(
      selectedFiles.video.file,
      userDetails?.user?.id,
      fileUpload[`video`],
      "dummy-co"
    );
    const uploadAudio =
      selectedFiles.audio?.file &&
      (await uploadFileWithProgress(
        selectedFiles.audio?.file,
        userDetails?.user?.id,
        fileUpload[`audio`],
        "dummy-co"
      ));
    const uploadCSV =
      (selectedFiles.csv?.file || !!textFileObject) &&
      (await uploadFileWithProgress(
        textFileObject ?? selectedFiles.csv?.file,
        userDetails?.user?.id,
        fileUpload[`csv`],
        "dummy-co"
      ));

    if (!uploadVideo) {
      toast({
        title: "Video file not supported",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    const assetsBody = [
      {
        name: `video-${new Date().getTime()}`,
        storage_path: uploadVideo.path,
        supabase_bucket: "video-files",
        type: "video",
      },
    ];
    if (uploadAudio)
      assetsBody.push({
        name: `audio-${new Date().getTime()}`,
        storage_path: uploadAudio.path,
        supabase_bucket: "audio-files",
        type: "audio",
      });

    if (uploadCSV)
      assetsBody.push({
        name: `csv-${new Date().getTime()}`,
        storage_path: uploadCSV.path,
        supabase_bucket: "csv-files",
        type: acceptDoc || hasCustomScript ? "doc" : "csv",
      });

    const body = {
      assets: assetsBody,
      avatar_type: avatarType,
      tags: tags,
      note: { description: description, title: title },
    };

    avatarRequest({ body }).then((result) => {
      if (result.error) {
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Avatar request has been submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        navigate(urls.submittedAvatars);
      }
    });
    setLoading(false);
  };

  const isLoading = useMemo(
    () => avatarLoading || loading,
    [avatarLoading, loading]
  );
  const isDisabled = useMemo(
    () => isEmpty(selectedFiles.video) || isLoading || loading,
    [selectedFiles, isLoading, loading]
  );

  return (
    <Box>
      <Card borderRadius={15} mb={3} p={7} bg={cardBg}>
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
          spacing={{ base: "40px", xl: "20px" }}
        >
          <Box gap={3} display="flex" flexDirection="column">
            <Input
              name="Title"
              placeholder="Enter the title for avatar..."
              value={title}
              type="text"
              color={textColor}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Box display="flex" flexDirection="column">
              <Text mb={1}>Tags</Text>
              <InputTag
                onTagsChange={setTags}
                maxWidth="100%"
                suggestionTag={suggestionTag}
                setSuggestionTag={setSuggestionTag} // Pass the setter for suggestions
              />
            </Box>
          </Box>
          <InputField
            name="Description"
            placeholder="Enter the avatar description..."
            value={description}
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            description
          />
        </SimpleGrid>
      </Card>
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
        spacing={{ base: "40px", xl: "20px" }}
      >
        <Card bg={cardBg} mb={0} borderRadius={15}>
          <Text px={4} pt={3} mt={2} color="blue.400" fontWeight="bold">
            A specific set of guidelines must be followed to record a video. For
            more details, refer to this
            <Link
              color="red.400"
              href="https://www.storydoc.com/8ae74e38d8d57219485587968a17d9e7/04ca9492-42be-4b86-b450-33a542f2199b/6695eab3d05b869eb22e463f"
              isExternal
            >
              &nbsp;guide
            </Link>
            .
          </Text>
          <Upload
            setSelectedFiles={setSelectedFiles}
            hideUpload={true}
            title="Upload Video Files"
            uploadIcon={FaVideo}
            description="MP4, MOV, and MKV files are allowed"
            accept="video"
            w="100%"
            h="300px"
            disabled={isLoading}
          />
        </Card>
        <Card bg={cardBg} mb={0} borderRadius={15}>
          <Text px={4} pt={3} mt={2} color="blue.400" fontWeight="bold">
            A specific set of guidelines must be followed to record a Audios.
            For more details, refer to this
            <Link
              color="red.400"
              href="https://www.storydoc.com/8ae74e38d8d57219485587968a17d9e7/91802fea-c325-43e0-ae15-6b7ab89dc4a0/6698349f58a9ed779f8a1321"
              isExternal
            >
              &nbsp;guide
            </Link>
            .
          </Text>
          <Upload
            setSelectedFiles={setSelectedFiles}
            hideUpload={true}
            title="Upload Audio Files"
            uploadIcon={GiSoundWaves}
            description="MP3, WAV and OGG files are allowed"
            accept="audio"
            w="100%"
            h="300px"
            optional={true}
            disabled={isLoading}
          />
        </Card>
        <Card bg={cardBg} mb={0} borderRadius={15}>
          <Text px={4} pt={3} mt={2} color="blue.400" fontWeight="bold">
            A specific set of guidelines must be followed for the csv file
            format. For details, see
            <Link
              color="red.400"
              href="https://jutouctdpjmetcyqorux.supabase.co/storage/v1/object/public/public_data/csv-samples/Example%20script%20.csv?t=2024-09-20T11%3A06%3A42.723Z"
              isExternal
            >
              &nbsp;this&nbsp;
            </Link>
            sample csv.
          </Text>
          <CustomScriptUpload
            hasCustomScript={hasCustomScript}
            setHasCustomScript={setHasCustomScript}
            customScriptContent={customScriptContent}
            setCustomScriptContent={setCustomScriptContent}
            isLoading={isLoading}
            setSelectedFiles={setSelectedFiles}
            hideUpload={true}
          />
        </Card>
      </SimpleGrid>
      <Box mt={5}>
        {csvData?.length ? (
          <CsvPreview height="450px" tableData={csvData ?? []} />
        ) : (
          ""
        )}
        {!!fileContentPreview && (
          <Textarea
            placeholder="File content"
            value={fileContentPreview}
            bg="white"
            rows={20}
            cursor="default"
          />
        )}
      </Box>

      <Flex direction="row" justifyContent="center">
        <Tooltip
          label="Please attach mandatory files"
          placement="top"
          bg={toolTipBg}
          color={textColor}
          isDisabled={!isEmpty(selectedFiles.video)}
        >
          <Button
            minW="140px"
            mt={{ base: "20px" }}
            variant="brand"
            fontWeight="500"
            onClick={handleUpload}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            Upload
          </Button>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default AvatarDetails;
