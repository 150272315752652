import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import FileMenu from "components/menu/FileMenu";
import { CiCalendar } from "react-icons/ci";

const VideoCard = ({ video, menuOptions, showMenu = false, showName, varified = false }) => {
  const textColor = useColorModeValue("black.900", "white.300");
  const shadowColor = useColorModeValue("#c7c7c7", "none");
  const [showControls, setShowControls] = useState(false);
  const videoBg = useColorModeValue("lightModeBg.200", "darkModeBg.100");

  const dateObj = new Date(video?.created_at);
  const updatedDateObj = new Date(video?.updated_at);

  return (
    <Card
      bg={""}
      minHeight="200px"
      p={0}
      background={videoBg}
      shadow={`1px 1px 8px 1px ${shadowColor}`}
    >
      <Flex h="100%" direction="column" justifyContent="center">
        <Box height="200px" borderRadius={15} bg="gray.100">
          <video
            poster={
              video?.preview_url ??
              video?.preview_image_url ??
              video?.verification_video_url
            }
            style={{
              width: "100%",
              height: "100%",
              aspectRatio: 3 / 2,
              objectFit: "contain",
            }}
            controls={showControls}
            onMouseOver={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
          >
            <source
              src={
                video?.url ??
                video?.preview_video_url ??
                video?.verification_video_url
              }
            />
          </video>
        </Box>
        <Box p={6}>
          <Flex justifyContent="space-between">
            {showName && (
              <Flex direction="column" alignItems="flex-start">
                <Text
                  fontSize="lg"
                  color={textColor}
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {video?.name ?? video?.avatar_name}
                </Text>
                {video?.is_processed && (
                  <Tag my={2} variant="outline">
                    Processed
                  </Tag>
                )}
              </Flex>
            )}
            {showMenu && (
              <Box ml="auto">
                <FileMenu menuOptions={menuOptions} id={video?.id} />
              </Box>
            )}
          </Flex>

          {/* Conditional rendering based on the 'varified' prop */}
          {varified ? (
            <>
              <Text fontWeight={700}>Updated At</Text>
              <HStack mt="2" spacing="2">
                <CiCalendar size={20} />
                {updatedDateObj ? (
                  <>
                    <Text fontSize="sm">{updatedDateObj.toLocaleDateString()}</Text>
                    <Text fontSize="sm">
                      {updatedDateObj.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Text>
                  </>
                ) : (
                  <Text fontSize="sm">Date not available</Text>
                )}
              </HStack>
            </>
          ) : (
            <HStack mt="2" spacing="2">
              <CiCalendar size={20} />
              {dateObj ? (
                <>
                  <Text fontSize="sm">{dateObj.toLocaleDateString()}</Text>
                  <Text fontSize="sm">
                    {dateObj.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Text>
                </>
              ) : (
                <Text fontSize="sm">Date not available</Text>
              )}
            </HStack>
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default VideoCard;
