import React, { useRef } from "react";
import { useGetAllFilesQuery } from "store/api/avatar";
import {
  Box,
  Heading,
  useColorModeValue,
  SimpleGrid,
  Card,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import AudioCard from "components/card/AudioCard";

const RecentAudio = () => {
  const { data, isLoading } = useGetAllFilesQuery({ type: "audio" });
  const tableBg = useColorModeValue("#fff", "#434343");
  const currentlyPlayingRef = useRef(null);
  const handlePlay = (audioRef) => {
    if (currentlyPlayingRef.current && currentlyPlayingRef.current !== audioRef) {
      currentlyPlayingRef.current.pause();
      currentlyPlayingRef.current.currentTime = 0;
    }
    currentlyPlayingRef.current = audioRef;
  };
  const recentAudioFiles = data?.data
    ?.slice()
    .sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at))
    .slice(0, 5);
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!recentAudioFiles || recentAudioFiles.length === 0) {
    return (
      <Card p={5} bg={tableBg} borderRadius={15} textAlign="start" >
      <Heading as="h2" size="lg" mb={4}>Recent Audio</Heading>
      <Box display='flex' justifyContent='center' alignItems='center' h={250} color='gray' fontWeight='bold' fontSize={20}>No Audio Available</Box>
  </Card>
    );
}

  return (
    <Box p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recent Audio
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
        {recentAudioFiles?.map((file) => (
          <AudioCard key={file.key} voice={file} onPlay={handlePlay}/>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RecentAudio;
